<template>
  <div class="container">
    <img src="https://img.chaolu.com.cn/ACT/training-record/middle-page.png" alt="" />
    <div @click="playVideo" class="play-area"></div>

    <!-- 讲解视频 -->
    <van-popup v-model="isVideoShow" :overlay="false" style="width: 100%; height: 100%; background: #000">
      <div class="popup-con col-center-center">
        <video
          @click="pause"
          ref="videoPlayer"
          class="video-js vjs-big-play-centered"
          playsinline="true"
          autoplay
          controls
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5-page"
          x5-video-player-fullscreen="false"
          x5-video-orientation="portraint"
          style="object-fit: cover; width: 100%; height: 90vh;"
          src="https://img.chaolu.com.cn/ACT/training-record/c94bceea4b23a3b1e894e0a61841cff6.mov" />
        <van-icon @click="videoClose" class="icon" color="#fff" size="28" name="cross" />
        <img v-show="!isPlay" @click="play" class="play-btn" src="http://img.chaolu.com.cn/MINI/icon/dongtai-play.png" alt="">
      </div>
    </van-popup>

    <div class="bottom-box">
      <div @click="copyLink" class="btn f28 fw6 row-center-center">下载超鹿运动APP</div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { loginOut, upgrade } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'

export default {
  mixins: [userMixin],
  data() {
    return {
      isVideoShow: false,
      isPlay: true
    }
  },
  async created() {
    await this.$getAllInfo()

    const ext1 = getParam().exerciseShareSource
    const isSelf = getParam().self // 自己点进来的

    if (!this.userId && !isSelf) {
      loginOut()
      return
    }

    this.countPoint('500', '500', '5015', this.userId, ext1)
  },

  methods: {
    playVideo() {
      this.isVideoShow = true
      this.play()
    },
    videoClose() {
      this.pause()
      this.isVideoShow = false
    },
    play() {
      this.isPlay = true
      this.$refs.videoPlayer.play()
    },
    pause() {
      this.isPlay = false
      this.$refs.videoPlayer.pause()
    },
    copyLink() {
      this.$copyText('http://a.app.qq.com/o/simple.jsp?pkgname=com.chaolu.app').then(
        (e) => {
          this.tips('已复制，打开浏览器下载')
        },
        (e) => {
          this.tips('复制失败')
        }
      )
    }
  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  background: #f5f5f5;
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }
  .play-area {
    width: 686px;
    height: 330px;
    position: absolute;
    top: 180px;
    left: 32px;
  }
  img {
    width: 100%;
  }
  .bottom-box {
    width: 100%;
    background-color: #fff;
    padding: 20px 40px calc(20px + env(safe-area-inset-bottom));
    position: sticky;
    bottom: 0;
    .btn {
      width: 100%;
      height: 104px;
      background: #FFDE00;
      border-radius: 100px;
    }
  }
}
/deep/.van-popup {
  .popup-con {
    height: 100vh;
    box-sizing: border-box;
    .mid {
      width: 100%;
      height: 90vh !important;
      position: relative;
    }
    .icon {
      position: absolute;
      top: calc(40px + env(safe-area-inset-top));
      left: 40px;
    }
    .play-btn {
        width: 120px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
      }
  }
}
</style>
