<template>
  <div>
    <nav-bar headerTitle="训记使用教程" ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container col-start-center">
      <van-list v-model="loading" :finished="finished" @load="getList">
        <div class="card" v-for="(item, index) in records" :key="index">
          <div class="bg row-center-center" :style="`background-image: url(${item.coverUrl})`">
            <img @click="playVideo(item)" class="icon" src="http://img.chaolu.com.cn/MINI/icon/dongtai-play.png" alt="" />
          </div>
          <div class="title f30 fw6">{{ item.title }}</div>
        </div>
      </van-list>
    </div>

    <!-- 讲解视频 -->
    <van-popup v-model="isVideoShow" :overlay="false" style="width: 100%; height: 100%; background: #000">
      <div class="popup-con col-center-center">
        <video
          @click="pause"
          ref="videoPlayer"
          class="video-js vjs-big-play-centered"
          playsinline="true"
          autoplay
          controls
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-player-type="h5-page"
          x5-video-player-fullscreen="false"
          x5-video-orientation="portraint"
          style="object-fit: cover; width: 100%; height: 90vh"
          :src="curItem.url" />
        <img v-show="!isPlay" @click="play" class="play-btn" src="http://img.chaolu.com.cn/MINI/icon/dongtai-play.png" alt="">
        <van-icon @click="videoClose" class="icon" color="#fff" size="28" name="cross" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar-202210/index'
import { newAppBack, initBack, loginOut } from '@/lib/appMethod'
// import Aliplayer from 'aliyun-aliplayer'
// import 'aliyun-aliplayer/build/skins/default/aliplayer-min.css'

export default {
  mixins: [userMixin],
  components: {
    navBar,
  },
  data() {
    return {
      records: [], // 训记列表

      loading: true,
      finished: false,
      isVideoShow: false,

      curItem: {},
      isPlay: true,
    }
  },
  computed: {},
  async created() {
    // 初始化
    await this.$getAllInfo()
    initBack()
    if (!this.userId) {
      loginOut()
      return
    }
    this.loading = false
  },

  methods: {
    newAppBack,

    getList() {
      this.$axios
        .post(this.baseURLApp + '/c/exercise/use/instruction/listUseTutorials', {
          userId: this.userId,
          token: this.token,
        })
        .then((res) => {
          this.records = res.data
          this.loading = false
          this.finished = true
        })
    },
    playVideo(item) {
      this.curItem = item
      this.isVideoShow = true
      this.play()
    },
    videoClose() {
      this.pause()
      this.isVideoShow = false
    },
    play() {
      this.isPlay = true
      this.$refs.videoPlayer.play()
    },
    pause() {
      this.isPlay = false
      this.$refs.videoPlayer.pause()
    },
  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}

.container {
  background-color: #f5f5f5;
  box-sizing: border-box;
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }

  width: 100%;
  padding: 0 32px env(safe-area-inset-bottom);
  .van-list {
    width: 100%;
  }
  .card {
    width: 100%;
    background: #ffffff;
    border-radius: 16px;
    margin: 32px 0 0;
    overflow: hidden;
    .bg {
      width: 100%;
      height: 336px;
      background-size: cover;
      background-position: center;
      .icon {
        width: 80px;
      }
    }
    .title {
      padding: 26px 32px;
    }
  }
}
/deep/.van-popup {
  .popup-con {
    height: 100vh;
    box-sizing: border-box;
    .mid {
      width: 100%;
      height: 90vh !important;
      position: relative;
    }
    .play-btn {
        width: 120px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
      }
    .icon {
      position: absolute;
      top: calc(40px + env(safe-area-inset-top));
      left: 40px;
    }
  }
}
</style>
