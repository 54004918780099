<template>
  <van-popup v-model="show_" round position="bottom" safe-area-inset-bottom @close="$emit('update:show', false)
">
    <div class="equity-popup">
      <van-swipe indicator-color="#000" :initial-swipe="equityIndex">
        <van-swipe-item v-for="(item, index) in equityList" :key="index">
          <img :v-lazy="item.popUpUrl" :src="item.popUpUrl" />
          <p class="p1 f40 fw6">{{ item.title }}</p>
          <p class="p2 f26">{{ item.introduce }}</p>
        </van-swipe-item>
      </van-swipe>

      <div v-if="vipStatus === 0" @click="buy" class="button f28 fw6 row-center-center">立即开通训记VIP</div>
    </div>
  </van-popup>
</template>

<script>
import { appOpenWeb } from '@/lib/appMethod'
export default {
  props: {
    show: {
      type: Boolean,
      value: false,
    },
    vipStatus: {
      type: Number
    },
    equityList: {
      type: Array,
      value: [],
    },
    equityIndex: {
      type: Number,
      value: 0,
    },
    actionType: {
      type: String,
      default: 'BUY'
    }
  },
  data() {
    return {
      show_: false,
    }
  },
  watch: {
    show(v) {
      this.show_ = v
    }
  },

  async created() {

  },
  methods: {
    buy() {
      console.log(this.actionType);

      if (this.actionType === 'BUY') {
        this.$emit('update:show', false)
        this.$emit('buy')
      }
      if (this.actionType === 'GO') {
        appOpenWeb('', `${window.location.origin}/#/training-record/mall?shopId=${this.$route.query.id}`)
      }
    }
  },
}
</script>
<style lang="less" scoped>
.equity-popup {
  text-align: center;
  .p1 {
    margin: 100px 0 0;
  }
  .p2 {
    margin: 34px 0 0;
    line-height: 40px;
    padding: 0 110px;
  }
  .button {
    width: 686px;
    height: 96px;
    margin: 0 auto 24px;
    background: linear-gradient(90deg, #9bff78 0%, #2ee251 100%), #ffde00;
    border-radius: 12px;
  }
  .van-swipe {
    overflow: initial;
    margin-bottom: 136px;
  }

  .van-swipe-item {
    width: 750px;
    img {
      width: 100%;
      height: 546px;
      object-fit: cover;
    }
  }
  /deep/.van-swipe__indicators {
    bottom: unset;
    top: 576px;
  }
}
</style>
