<template>
  <div class="page-box">

    <!-- 小程序 头部 -->
    <p v-if="appTypeStr === 'mini'" class="f26 right-button" @click="goRecord">开通记录</p>
    <!-- app 头部 -->
    <div v-else :class="['head-bar f48', hasBanner ? 'immersive' : '']">
      <status-bar v-if="appTypeStr !== 'mini'" backColor="#fff" :show-back="true" :header-back="newAppBack" />
      <div class="row-between-center">
        <div class="row-start-center">
          <div class="back-box f32 row-center-center"><van-icon  @click="newAppBack" name="arrow-left" /></div>
          <div v-if="!hasBanner" class="title f34 fw6">超鹿训记VIP</div>
        </div>
        <div class="row-start-center f24">
          <p class="bar-btn row-center-center" @click="goMall">VIP商城</p>
          <p class="bar-btn row-center-center" @click="goRecord">开通记录</p>
        </div>
      </div>
    </div>

    <!-- 营销banner -->
    <div v-if="hasBanner" class="banner-box">
      <img class="banner" src="https://img.chaolu.com.cn/mainImg/2024-11-04/1730718407.jpg?x-oss-process=style/compress" alt="" />
      <div class="mask"></div>
    </div>

    <!-- vip卡面 -->
    <div class="vip-box">
      <div :class="['vip-card col-between-start', hasBanner ? 'immersive' : '']" :style="`background-image: url(${baseInfo.cardImg})`">
        <div class="row-start-center">
          <img :src="userInfo.headImg" alt="" class="avator" />
          <p class="p1 f28 fw">{{ userInfo.userName }}</p>
        </div>
        <div class="row-between-center">
          <p class="p2 f24">VIP有效时间：{{ baseInfo.vipValidityText }} <span class="p2" v-if="baseInfo.vipStatus === 1"> 到期</span></p>
          <!-- <div @click="goMall" v-if="baseInfo.vipStatus === 1" class="btn row-center-center f24 fw6">续费</div> -->
        </div>
      </div>
    </div>

    <div class="main">
      <!-- 权益列表 -->
      <div class="equity-box">
        <div class="card-title row-between-center">
          <p class="f32 fw6">尊享权益</p>
        </div>
        <div class="equity-list row-start-between flex-wrap">
          <img @click="showEquityPopup(item, index)" v-for="item,index in introduceList" :key="item.title" :src="item.introduceImg" alt="" class="equity-item" />
        </div>
      </div>
    </div>

    <!-- 权益弹窗 -->
    <equity-popup :show.sync="isEquityShow" :vipStatus="baseInfo.vipStatus" :equityList.sync="introduceList" :equityIndex="equityIndex" actionType="GO"></equity-popup>

    <!--  分享  -->
    <common-share :value="isShareShow" :shareParams="shareParams" :shareItem="['minifriend']" @close="isShareShow = false" @share-success="isShareShow = false"></common-share>
  </div>
</template>
<script>
import userMixin from '@/mixin/userMixin'
import commonShare from '@/components/commonShare'
import statusBar from '@/components/app/statusBar'
import equityPopup from './components/equity-popup'

import { initBack, newAppBack, upgrade, appOpenWeb, hideAppBackBtn } from '@/lib/appMethod'
import wx from 'weixin-js-sdk'

export default {
  mixins: [userMixin],
  components: { statusBar, commonShare, equityPopup },
  data() {
    return {
      isShareShow: false,
      shareParams: {
        title: '购买超鹿月卡，享超值福利。',
        miniImage: 'https://img.chaolu.com.cn/ACT/sell-membership-2022/month/share.png',
        path: '/pages/webView/index?webUrl=' + window.location.origin + '/#/superdeer-activity/sell-membership',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },

      isEquityShow: false,
      equityIndex: 0,
      images: [],
      hasBanner: false,

      baseInfo: {},
      introduceList: [],
      userInfo: {}
    }
  },

  mounted() {},

  computed: {},
  async created() {
    hideAppBackBtn()
    initBack()
    await this.$getAllInfo(['userId', 'cityId', 'local'])
    this.getBaseInfo()
    // const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/sell-membership`)
    // this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
    // wx.miniProgram.postMessage({
    //   data: {
    //     type: 'share',
    //     shareImage: this.shareParams.miniImage,
    //     shareTitle: this.shareParams.title,
    //     shareUrl: this.shareParams.path,
    //   },
    // })
  },
  methods: {
    newAppBack,
    upgrade,
    getBaseInfo() {
      let toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
        message: '加载中...',
        loadingType: 'spinner',
      })
      this.$axios
        .post(this.baseURLApp + '/c/user/equity/card/vipInfo', {
          userId: this.userId,
        })
        .then((r) => {
          this.baseInfo = r.data
          this.introduceList = r.data.introduceList
          this.userInfo = r.data.user
          toast.clear()
        })
        .catch((r) => {

        })
    },
    showEquityPopup(item, index) {
      this.isEquityShow = true
      this.equityIndex = index
    },
    goRecord() {
      appOpenWeb('', `${window.location.origin}/#/training-record/vip-use-list`)
    },
    goMall() {
      appOpenWeb('', `${window.location.origin}/#/training-record/mall?id=${this.$route.query.id}`)
    },
    close() {},
  },
}
</script>
<style lang="less" scoped>
.share-icon {
  width: 38px;
}

.page-box {
  width: 100%;
  min-height: 100vh;
  background: #1a1a1a;
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }
  & > div {
    width: 100%;
  }
  .main {
    padding: 36px 32px 124px;
    // margin: -24px 0 0;
    background: linear-gradient(180deg, #2b241f 0%, #080808 100%);
    border-radius: 24px 24px 0px 0px;
  }
  .right-button {
    padding: 8px 12px 8px 18px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #080808;
    position: fixed;
    right: 0;
    top: 44px;
    border-radius: 32px 0 0 32px;
    z-index: 99;
  }
  .head-bar {
    background: linear-gradient(180deg, #6d4b2f 0%, #3c291c 100%);
    position: sticky;
    top: 0;
    color: #fff;
    padding: 18px 32px;
    z-index: 2;
    &.immersive {
      position: fixed;
      background: transparent;
    }
    .back-box {
      width: 56px;
      height: 56px;
      background: rgba(0,0,0,0.3);
      border-radius: 50%;
    }
    .title {
      margin: 0 0 0 20px;
    }
    .bar-btn {
      padding: 0 26px;
      height: 56px;
      background: rgba(0,0,0,0.3);
      border-radius: 114px 114px 114px 114px;
      margin: 0 0 0 16px;
    }
  }
  .banner-box {
    position: relative;
    min-height: 260px;
    .banner {
      width: 100%;
    }
    .mask {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 128px;
      background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1a1a1a 100%);
      border-radius: 0px 0px 0px 0px;
    }
  }

  .vip-box {
    width: 100%;
    padding: 32px;
    position: relative;
    background: linear-gradient( 180deg, #3C291C 0%, #1A110E 100%);
    .vip-card {
      width: 100%;
      height: 280px;
      padding: 36px 32px 28px;
      border-radius: 18px 18px 18px 18px;
      background: linear-gradient(175deg, #705f49 0%, #3e2e1f 100%);
      background-size: cover;

      .avator {
        width: 56px;
        height: 56px;
        border-radius: 50%;
      }
      .p1 {
        color: #fff;
        margin: 0 0 0 16px;
      }
      .p2 {
        color: rgba(255, 255, 255, 0.9);
      }
      .btn {
        padding: 0 38px;
        height: 56px;
        background: linear-gradient(222deg, #ffffff 0%, #fff7df 100%);
        border-radius: 32px;
      }
      &.immersive {
        margin: -80px auto 0;
      }
    }
  }

  .equity-box {
    color: #f7d5b6;
    .equity-list {
      margin: 32px 0 0;
      display: flex;
      justify-content: space-between;
    }
    .equity-item {
      width: 332px;
      margin: 0 0 22px;
      border-radius: 16px;
    }
  }
}

.equity-popup {
  text-align: center;
  .p1 {
    margin: 100px 0 0;
  }
  .p2 {
    margin: 34px 0 0;
    line-height: 40px;
    padding: 0 110px;
  }
  .button {
    width: 686px;
    height: 96px;
    margin: 136px auto 24px;
    background: linear-gradient(90deg, #9bff78 0%, #2ee251 100%), #ffde00;
    border-radius: 12px;
  }
  .van-swipe {
    overflow: initial;
  }

  .van-swipe-item {
    width: 750px;
    height: 546px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

/deep/ .red {
  color: #ff6e00;
}
.white {
  color: #fff;
}
.green {
  color: #26d548;
}
</style>
