<template>
  <div class="bottom-box">
      <div @click="go" class="btn f28 fw6 row-center-center">查看超鹿训记</div>
    </div>
</template>

<script>
import { appOpenWeb } from '@/lib/appMethod'
export default {
  data() {
    return {

    }
  },

  async created() {

  },
  methods: {
    go() {
      appOpenWeb('', `${window.location.origin}/#/training-record/introduction`)
    }
  },
}
</script>
<style lang="less" scoped>
.bottom-box {
    width: 100%;
    box-sizing: border-box;
    background-color: #242424;
    padding: 20px 40px calc(20px + env(safe-area-inset-bottom));
    position: sticky;
    bottom: 0;
    .btn {
      width: 100%;
      height: 104px;
      background: #FFDE00;
      border-radius: 100px;
    }
  }
</style>
