<template>
  <div>
    <van-popup v-model="show" :safe-area-inset-bottom="true" :overlay="showCover" @close="closePopup" position="bottom" :close-on-click-overlay="false" :lock-scroll="true" :closeable="true">
      <div class="content">
        <div class="title">{{ payData.boxTitle ? payData.boxTitle : '确认订单' }}</div>
        <div class="name row-between-center">
          <div class="left">商品名称</div>
          <div class="left fw6">{{ payData.subject ? payData.subject : '' }}</div>
        </div>
        <div v-if="payData.originalPriceStr" class="goodsNum row-between-center">
          <div class="left">价格</div>
          <div class="left fw6">¥{{ payData.originalPriceStr }}</div>
        </div>
        <div v-if="payData.selectedDiscount" class="goodsNum row-between-center">
          <div class="left">优惠</div>
          <div>
            <span class="s2">-¥</span><span class="s3">{{ payData.selectedDiscount }}</span>
          </div>
        </div>
        <div v-if="payData.totalAmount" class="goodsNum row-between-center">
          <div v-if="payData.firstPrice && !payData.extendsJson.giveUpFirstBuy" class="left">新客专享价</div>
          <div v-else class="left">限时售价</div>
          <div class="left fw6">¥{{ payData.totalAmount }}</div>
        </div>

        <div class="pay-type">选择支付方式</div>
        <!-- 支付方式 -->
        <template v-for="item in payTypeList" >
          <div v-if="item.useEnv.includes(appTypeStr)" :key="item.payType" @click="payType = item.payType" :class="['pay-checked', 'row-between-center', payType === item.payType ? 'active' : '']">
            <div class="left">
              <img :src="item.icon" alt="" />
              {{ item.name }}
            </div>
          </div>
        </template>

        <div @click="pay()" class="pay-btn row-center-center fw6">￥{{ submitMoney }} 去支付</div>
      </div>
    </van-popup>

  </div>
</template>
<script>
import userMixin from '@/mixin/userMixin'
import coupon from '@/components/pay-popup-card/coupon'
import { compareVersion } from "@/lib/utils"
import { silentAppPay, getAppVersion } from '@/lib/appMethod'
export default {
  mixins: [userMixin],
  props: {
    showCover: {
      type: Boolean,
      default: true,
    },
    showPayPopup: {
      type: Boolean,
      default: false,
    },
    payData: {
      type: Object,
      default() {
        return {}
      },
    },
    payUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
      payType: 1, // 支付类型 默认1， 只在小程序内使用，默认只有微信
      showCoupon: false,
      // 鹿充券列表
      list: [],
      unavailableList: [],
      // 支付参数
      selectedDiscount: 0,
      selectedCouponId: '',
      // submitMoney: 0 // 最终支付金额
      version: '',
    }
  },
  computed: {
    submitMoney() {
      return this.payData.totalAmount
    },
    payTypeList() {
      if (compareVersion(this.version, '4.35.10') < 0) {
        return [
          { payType: 1, name: '微信支付', icon: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/wechatpay.png', useEnv: ['and', 'ios', 'mini'] },
          { payType: 2, name: '支付宝支付', icon: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/alipay.png', useEnv: ['and', 'ios'] },
        ]
      } else {
        return [
          { payType: 1, name: '微信支付', icon: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/wechatpay.png', useEnv: ['and', 'ios', 'mini'] },
          { payType: 2, name: '支付宝支付', icon: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/alipay.png', useEnv: ['and', 'ios'] },
          { payType: 3, name: '云闪付支付', icon: 'https://img.chaolu.com.cn/ACT/icon/unionpay2.png', useEnv: ['and', 'ios'] },
          // { payType: 4, name: '云闪付免密支付', icon: 'https://img.chaolu.com.cn/ACT/icon/unionpay1.png', useEnv: ['and', 'ios', 'mini'] },
        ]
      }
    }
  },
  async created() {
    this.version = await getAppVersion()
  },
  methods: {
    closePopup() {
      this.showCoupon = false
      this.show = false
      this.$emit('update:showPayPopup', false)
    },

    pay() {
      let payParam = JSON.parse(JSON.stringify(this.payData)),
        url = this.payUrl
      payParam.totalAmount = this.submitMoney
      payParam.discountId = this.selectedCouponId
      payParam.extendsJson.discountId = this.selectedCouponId
      this.show = false
      this.$emit('payComplete', this.selectedCouponId || (!this.payData.extendsJson.giveUpFirstBuy && this.payData.firstPrice) ? 1 : 0)
      silentAppPay(this.payType, payParam, url)
    },
  },
  watch: {
    async showPayPopup(val) {
      // 显示隐藏
      console.log(this.payData)
      this.show = val
      if (val) {
        // 优惠券金额和id 如果从父级传进来就取父级的 没有就取可用优惠券列表返回的最大值
        this.selectedDiscount = this.payData.selectedDiscount || 0
        this.selectedCouponId = this.payData.discountId || ''
      } else {
        this.closePopup()
      }
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  padding: 0 32px 32px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.coupon-box {
  background: #f5f5f5;
  padding-bottom: 0;
  height: 826px;
}

.pay-btn {
  width: 686px;
  height: 96px;
  background: #ffde00;
  border-radius: 8px;
  color: #242831;
  font-size: 32px;
  margin-top: 72px;
  margin-bottom: 38px;
}
.coupon-item {
  color: #242831;
  font-size: 24px;
  font-weight: bold;
  padding: 32px 0 18px;
  border-top: 1px solid #e6e6e6;
}
.coupon-item-tips {
  color: #242831;
  font-size: 24px;
}
.coupon {
  height: 100px;
  box-sizing: border-box;
  //border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #e6e6e6;
  background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/arrow-right.png') no-repeat center right;
  background-size: 24px 24px;
  padding-right: 34px;
  margin-top: 12px;
  color: #9aa1a9;
  font-size: 24px;
}
.coupon-title {
  font-weight: bold;
  color: #242831;
  font-size: 36px;
  padding-left: 56px;
  height: 144px;
  display: flex;
  align-items: center;
  background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/arrow-left.png') no-repeat center left;
  background-size: 40px 40px;
}
.coupon-price-red {
    color: #fb732e;
  }
  .s1 {
    font-weight: bold;
    color: #242831;
    font-size: 24px;
  }
  .s2 {
    font-family: BebasNeueBold;
    font-size: 32px;
    font-weight: bold;
    color: #f03c18;
    margin-right: 4px;
  }
  .s3 {
    font-family: BebasNeueBold;
    color: #f03c18;
    font-size: 40px;
    font-weight: bold;
  }
.coupon-list {
  height: calc(100% - 144px);
  overflow-y: scroll;
}

.coupon-list::-webkit-scrollbar {
  display: none;
}

.price {
  padding: 28px 0 32px;
}

.price > div .s1 {
  text-decoration: line-through;
  color: #6c727a;
  line-height: 28px;
  font-size: 24px;
  margin-right: 16px;
}

.price > div .s2 {
  font-family: BebasNeueBold;
  color: #242831;
  font-size: 24px;
  font-weight: bold;
}

.price > div .s3 {
  font-family: BebasNeueBold;
  color: #242831;
  font-size: 40px;
  font-weight: bold;
}

.left {
  font-size: 24px;
  color: #242831;
  line-height: 28px;
}

.title {
  color: #242831;
  font-size: 36px;
  font-weight: bold;
  padding: 48px 0;
}

.pay-type {
  color: #242831;
  font-size: 24px;
  font-weight: bold;
  padding: 32px 0 8px;
  margin: 64px 0 0;
}
.pay-checked {
  background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/normal.png') no-repeat center right;
  background-size: 40px 40px;
  padding: 24px 0;

  &.active {
    background-image: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/active.png');
  }

  div {
    display: flex;
    align-items: center;
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
}

.coupon-box .item {
  background-color: white;
}

.coupon-box .item {
  margin-bottom: 32px;
}

.coupon-box .item-i {
  display: flex;
  justify-content: space-between;
  //align-items: center;
}

.coupon-box .item-l {
  width: 192px;
  height: 200px;
  background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/card-coupon-active.png');
  background-size: 100% 100%;
  color: white;
  font-size: 20px;
  flex-shrink: 0;
}

.coupon-box .item-l > div:first-child {
  font-family: BebasNeueBold;
  font-size: 80px;
}

.coupon-box .item-l > div:first-child span {
  font-size: 36px;
}

.coupon-box .item-r {
  flex-grow: 1;
  box-sizing: border-box;
  padding-left: 16px;
  position: relative;
}

.coupon-box .item-r .coupon-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}

.coupon-box .item-r .coupon-tabs span {
  padding: 4px 8px;
  font-weight: bold;
  color: #fb732e;
  font-size: 18px;
  border: 1px solid #fb732e;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-right: 8px;
}

.coupon-box .item-r .coupon-date {
  margin-top: 38px;
  font-size: 20px;
  line-height: 23px;
  color: #6c727a;
  position: absolute;
  left: 16px;
  bottom: 24px;
}

.coupon-box .item-r .coupon-name {
  line-height: 28px;
  font-weight: bold;
  font-size: 24px;
  padding-top: 24px;
}

.goodsNum {
  margin-top: 48px;
}

.coupon-box .item-r .arrow {
  position: absolute;
  right: 4px;
  bottom: 8px;
  width: 24px;
  height: 24px;
  transition: transform 0.5s;
  padding: 20px;
}

.coupon-box .arrow.rotate {
  transform: rotateZ(180deg);
}
.c-container {
  margin-top: 48px;
}
</style>
