<template>
  <div class="page-rooter-fff">
    <nav-bar :headerTitle="title" :showBack="true" :header-back="appBack"></nav-bar>
    <app-page>
      <div class="content" v-html="content"></div>
    </app-page>

  </div>
</template>

<script>
import appMixin from '@/mixin/appMixin'
import {hideAppBackBtn, appBack} from "@/lib/appMethod";
import navBar from '@/components/nav-bar/nav-bar'
import appPage from '@/common/components/appPage'
import { getParam } from '@/lib/utils'

export default {
  data() {
    return {
      title: '会员服务协议',
      content: '等产品给哦哦好的嗯呢恩山东科技看待上课富家大室看发是砥砺奋进SDK阿萨德李逵负荆SDK风就是的是打开房间的数量福建省到付件待上课富家大室富家大室来看待上',
    };
  },
  mixins: [appMixin],
  components: {
    navBar,
    appPage,
  },
  created() {

    try {
      hideAppBackBtn()
    } catch (e) {}

  },
  methods: {

    appBack() {
      appBack();
    },
  },
};
</script>

<style lang="less" scoped>
  .page-rooter-fff {
    padding: 32px 0 32px;
    h2 {
      width: 100%;
      text-align: center;
      margin: 0 0 56px;
    }
    .content {
      box-sizing: border-box;
      width: 100%;
      padding: 0 32px;
      font-size: 32px;
    }
  }
</style>
