<template>
  <div class="page-box">
    <!-- 头部 -->
    <div class="head-bar f48">
      <status-bar v-if="appTypeStr !== 'mini'" header-title="训记VIP商城" backColor="#fff" :show-back="true" :header-back="newAppBack" />
      <div class="row-between-center">
        <div class="row-start-center">
          <div class="back-box f32 row-center-center"><van-icon @click="newAppBack" v-if="appTypeStr !== 'mini'" name="arrow-left" /></div>
          <div class="user row-start-center">
            <div class="avator-box">
              <img :src="userInfo.headImg" alt="" class="avator" />
              <img v-if="baseInfo.vipStatus !== 0" src="https://img.chaolu.com.cn/ACT/training-record/202410/5.png" alt="" class="tag" />
            </div>
            <div>
              <p class="p1 f28 fw6">{{ userInfo.userName }}</p>
              <p v-if="baseInfo.vipStatus === 0" class="f22"><span class="p3">您还不是VIP</span></p>
              <p v-else class="f22">
                <span class="p2">VIP有效期：</span><span class="green">{{ baseInfo.vipValidityText }}</span
                ><span class="p2" v-if="baseInfo.vipStatus === 1"> 到期</span>
              </p>
            </div>
          </div>
        </div>
        <p class="f26" @click="isConfirmShow = true">兑换</p>
      </div>
    </div>
    <div class="main">
      <!-- vip卡面 -->
      <img class="vip-box" src="https://img.chaolu.com.cn/ACT/training-record/202410/vip-card.png" alt="" />

      <!-- 卡包列表 -->
      <div v-if="AI.partitions" class="card-box">
        <div class="card-title row-between-center">
          <p class="f28 fw6">选择套餐</p>
        </div>

        <div class="card-list flex flex-wrap">
          <div @click="selectCard(item)" :class="['card-item col-end-start', index === 0 ? 'forever' : '', item.id === selectItem.id ? 'active' : '']" v-for="(item, index) in AI.partitions.subfieldsInfos[0].groupInfos[0].goodsInfos" :key="index">
            <p class="ellipsis card-name f26 fw6">{{ item.goodsName }}</p>

            <div class="flex-auto">
              <p class="item-tips f20" v-for="(t, i) in replaceDot(item.productTags)" :key="i">{{ t }}</p>
            </div>

            <div class="price row-start-end">
              <div class="dis-price f60 BebasNeueBold">
                <span class="f26">¥ </span>
                <span v-if="index === 0 && goodsPriceStatusMap[item.id] && goodsPriceStatusMap[item.id].discountType === 'FIRST_BUY' && isRedbagShow" style="overflow: hidden; position: relative">
                  <span :class="['dis-1', isNewAni]">{{ salePrice(item) }}</span>
                  <span :class="['dis-2', isNewAni]">{{ item.salePrice }}</span>
                </span>
                <span v-else :class="['dis-2']">{{ salePrice(item) }}</span>
              </div>
              <div class="ori-price f20">{{ item.originPrice }}</div>
            </div>

            <div v-if="goodsPriceStatusMap[item.id] && goodsPriceStatusMap[item.id].endTime" class="limit f18 fw6 row-center-center">
              <img v-show="goodsPriceStatusMap[item.id].discountType === 'FIRST_BUY'" src="https://img.chaolu.com.cn/ACT/training-record/202410/2.png" alt="" class="limit-icon" />
              <p class="limit-time f20 row-start-center">限时<van-count-down :time="goodsPriceStatusMap[item.id].endTime"/></p>
            </div>
            <!-- 占位 -->
            <div v-else class="limit" style="opacity: 0;"></div>

            <span v-if="tags(item)" class="tag f18 fw6 row-center-center">{{ tags(item) }}</span>

            <img v-show="item.id === selectItem.id && goodsPriceStatusMap[selectItem.id] && goodsPriceStatusMap[selectItem.id].text" src="https://img.chaolu.com.cn/ACT/training-record/202410/1.png" alt="" class="sj-icon" />
          </div>
        </div>

        <div v-if="goodsPriceStatusMap[selectItem.id] && goodsPriceStatusMap[selectItem.id].text" class="card-tips row-between-center f24 fw6">
          <div class="row-start-center">
            <img :src="`https://img.chaolu.com.cn/ACT/training-record/202410/${goodsPriceStatusMap[selectItem.id].discountType === 'FIRST_BUY' ? '2' : '4'}.png`" alt="" class="icon" />
            <p>{{ discountPrice ? goodsPriceStatusMap[selectItem.id].text : '未使用优惠券' }}</p>
          </div>
          <p @click="showCouponPopup" class="f22 red">{{ discountPrice ? '已减¥' : '' }}{{ discountPrice }}<van-icon v-if="goodsPriceStatusMap[selectItem.id].discountType !== 'FIRST_BUY'" name="arrow" /></p>
        </div>
      </div>
      <!-- 权益列表 -->
      <div class="equity-box">
        <div class="card-title row-between-center">
          <p class="f28 fw6 white">权益列表</p>
        </div>
        <div class="equity-list flex-wrap">
          <img @click="showEquityPopup(item, index)" v-for="(item, index) in introduceList" :key="item.title" :src="item.introduceImg" alt="" class="equity-item" />
        </div>
      </div>
      <!-- rule -->
      <div class="rule-box">
        <div class="rule-list">
          <div @click="goRecord" class="rule-item f26 row-between-center">
            <p>开通记录</p>
            <van-icon name="arrow" />
          </div>
          <div @click="goArticle(1, '会员服务协议')" class="rule-item f26 row-between-center">
            <p>会员服务协议</p>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>

    <!-- 吸底 购买按钮 -->
    <div class="bottom-btn">
      <!-- 购买按钮 -->
      <div class="buy-card-btn row-between-center fw6">
        <div class="row-start-center">
          <h4 class="f40">
            <span class="f24">¥&nbsp;</span><span class="fw6">{{ totalAmount | priceFormat }}</span>
          </h4>
          <div v-if="discountPrice" class="tag f18 row-center-center">已减¥{{ discountPrice }}</div>
        </div>
        <div class="btn f28 row-center-center" @click="checkAwaitOrder">立即开通</div>
      </div>
      <!-- 勾选协议 -->
      <div class="protocol f22 flex" :class="{ shake: shake }">
        <p @click="protocol = !protocol" :class="{ active: protocol }"></p>
        <div>
          <span @click="protocol = !protocol">点击开通代表您已阅读并同意</span>
          <span class="s1 fw6"  @click="goArticle(1, '会员服务协议')">《会员服务协议》</span>
        </div>
      </div>
    </div>

    <!-- 权益弹窗 -->
    <equity-popup :show.sync="isEquityShow" :vipStatus="baseInfo.vipStatus" :equityList.sync="introduceList" :equityIndex="equityIndex" @buy="checkAwaitOrder"></equity-popup>
    <!-- 兑换弹窗 -->
    <confirm type="2" :contentType="3" placeholder="输入兑换码" :showConfirm="isConfirmShow" title="输入训记VIP兑换码" :btnTextArr="['取消', '确认']" @firstBtn="isConfirmShow = false" @secondBtn="exchangeCheck" />
    <!-- 新人立减弹窗 -->
    <van-popup v-if="isRedbagShow" v-model="isNewSaleShow" @close="newClose" round style="background-color: transparent">
      <div class="new-popup col-start-center">
        <van-icon @click="newClose" name="clear" size="24px" color="rgba(255, 255, 255, .7)" />
        <div class="red-bag fw6 col-start-center">
          <p class="p1 f40">立减 <span class="s1">¥</span><span class="s2">{{ discountPrice }}</span></p>
          <div class="limit f26 row-center-center">限时 <van-count-down :time="goodsPriceStatusMap[this.selectItem.id].endTime" style="color:#FFF4d4"/></div>
          <p class="p2 f32">恭喜获得新人优惠</p>
          <img @click="newClose" src="https://img.chaolu.com.cn/ACT/training-record/202410/button.png" alt="" class="button" />
        </div>
        <p class="p3 f24">{{ closeTime }}s后自动收下</p>
      </div>
    </van-popup>
    <!-- 开通成功弹窗 -->
    <van-popup v-model="isSuccessShow" @close="startUse" round style="background-color: transparent">
      <div class="success-popup col-start-center">
        <img :src="userInfo.headImg" alt="" class="avator" />
        <p class="p1 f44 fw6">{{ userInfo.userName }}</p>
        <p class="p2 f26">你已是超鹿训记VIP会员</p>
        <div @click="startUse" class="button f28 fw6 row-center-center">立即使用</div>
      </div>
    </van-popup>

    <!--错误弹窗提示-->
    <van-popup v-model="showError" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false">
      <div class="error-box">
        <h4>提示</h4>
        <p>{{ errorMsg.msg }}</p>
        <div @click="closeError" class="btn row-center-center">我知道了</div>
      </div>
    </van-popup>

    <!-- 支付弹窗 -->
    <payPopup :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl" @payComplete="payComplete"></payPopup>
    <coupon :unavailableList="unavailableList" :discount="selectedDiscount" :couponId="selectedCouponId" :list="voucherList" @confirm="confirmVoucher" :showCoupon.sync="isCouponShow"></coupon>
    <!--  分享  -->
    <common-share :value="isShareShow" :shareParams="shareParams" :shareItem="['minifriend']" @close="isShareShow = false" @share-success="isShareShow = false"></common-share>
  </div>
</template>
<script>
import userMixin from '@/mixin/userMixin'
import commonShare from '@/components/commonShare'
import statusBar from '@/components/app/statusBar'
import payPopup from './components/pay-popup'
import coupon from '@/components/pay-popup-card/coupon'
import confirm from '@/components/confirm/confirm'
import equityPopup from './components/equity-popup'

import { appPaySuccess, initBack, newAppBack, gotoOrderList, closeWindow, upgrade, appOpenWeb, hideAppBackBtn } from '@/lib/appMethod'
import wx from 'weixin-js-sdk'
import { getParam } from '@/lib/utils'

export default {
  mixins: [userMixin],
  components: { confirm, statusBar, payPopup, coupon, commonShare, equityPopup },
  data() {
    return {
      shopId: '',
      isShareShow: false,
      shareParams: {
        title: '购买超鹿月卡，享超值福利。',
        miniImage: 'https://img.chaolu.com.cn/ACT/sell-membership-2022/month/share.png',
        path: '/pages/webView/index?webUrl=' + window.location.origin + '/#/superdeer-activity/sell-membership',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      isCouponShow: false,

      selectItem: {}, // 当前选择卡下标
      cardInfo: {
        items: [],
      },
      protocol: false, // 勾选用户协议
      showError: false,
      shake: false,
      errorMsg: {},

      showPayPopup: false,
      payData: {},
      payUrl: '',
      voucherList: [],
      unavailableList: [], //不可用列表
      selectedDiscount: 0,
      selectedCouponId: '',

      expireDayTime: '', // 失效提示

      channelCode: '', // 购买渠道标识

      isEquityShow: false,
      isConfirmShow: false,
      isSuccessShow: false,
      isNewSaleShow: false,
      isRedbagShow: false,
      equityIndex: 0,
      images: [],

      isNewAni: '',
      introduceList: [],
      userInfo: {},
      baseInfo: {},
      AI: {},
      goodsPriceStatusMap: {}, // 商品对应的价格状态
      cdKey: '',
      closeTime: 3,
      timer: null,
    }
  },

  filters: {
    priceFormat(v) {
      if (!v) {
        return 0
      }
      return parseFloat(v)
    },
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.isShareShow = false
        this.getBaseInfo()
        this.getShop()
      } else if (document.visibilityState === 'hidden') {
        this.isShareShow = false
      }
    })
  },

  computed: {
    salePrice() {
      return (item) => {
        if (!item.id || !this.goodsPriceStatusMap[item.id]) {
          return 0
        }
        const discountObj = this.goodsPriceStatusMap[item.id]
        if (discountObj.discountType === 'FIRST_BUY') {
          return discountObj.discountPrice
        }

        if (this.selectItem.id === item.id) {
          if (!this.selectedDiscount || !this.selectedCouponId) {
            return item.salePrice
          }
        } else {
          return discountObj.discountPrice !== undefined ? discountObj.discountPrice.toFixed(2) : item.salePrice
        }
        // 最终售价
        let p = item.salePrice || 0
        let voucherAfter = this.selectedDiscount || 0
        let price = p - voucherAfter < 0 ? 0 : p - voucherAfter
        return price.toFixed(2)
      }
    },
    totalAmount() {
      return this.salePrice(this.selectItem)
    },
    discountPrice() {
      if (!this.selectItem.id || !this.goodsPriceStatusMap[this.selectItem.id]) {
        return ''
      }
      console.log('he')

      const discountObj = this.goodsPriceStatusMap[this.selectItem.id]
      if (discountObj.discountType === 'FIRST_BUY') {
        return discountObj.discount
      }
      console.log('hes')
      // 不选券使用限时售卖价
      if (this.selectedDiscount) {
        return this.selectedDiscount
      }
      console.log('hesss')
      return ''
    },
    tags() {
      return (item) => {
        if (!item.id || !this.goodsPriceStatusMap[item.id]) {
          return ''
        }
        const discountObj = this.goodsPriceStatusMap[item.id]
        if (discountObj.discountType === 'FIRST_BUY') {
          return '新人优惠'
        }
        return item.plugTags
      }
    },
  },
  async created() {
    hideAppBackBtn()
    initBack()
    await this.$getAllInfo(['userId', 'venueId', 'cityId'])

    const q = this.$route.query
    this.shopId = q.id || '10146'
    this.getBaseInfo()

    this.getShop()

    if (this.appTypeStr === 'mini') {
      appPaySuccess().then((res) => {})
    }

    // const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/sell-membership`)
    // this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
    // wx.miniProgram.postMessage({
    //   data: {
    //     type: 'share',
    //     shareImage: this.shareParams.miniImage,
    //     shareTitle: this.shareParams.title,
    //     shareUrl: this.shareParams.path,
    //   },
    // })
    if (this.$route.query.channelCode) {
      this.channelCode = this.$route.query.channelCode
      this.$axios
        .post(this.baseURLApp + '/dataPoint/dataPoint', {
          userId: this.userId || 0,
          activityType: 401,
          activityId: 401,
          templateId: 40002,
          count: '0',
          amount: '0',
          ext1: this.$route.query.channelCode,
        })
        .then((res) => {
          console.log('埋点成功', this.$route.query.channelCode)
        })
    }
  },
  methods: {
    newAppBack,
    upgrade,
    closeError() {
      this.showError = false
      if (this.errorMsg.skipOrder) {
        gotoOrderList()
      }
    },
    confirmVoucher(item) {
      if (item) {
        this.selectedDiscount = item.discount || 0
        // this.expireDayTime = item.expireDayTime || 0
        this.selectedCouponId = item.id || ''
      }
    },

    // 获取我的优惠券最高金额  及 可用和不可用列表
    getVoucherList(id) {
      this.$axios
        .post(
          `${this.baseURLApp}/voucher/exerciseVipVoucher`,
          {
            userId: this.userId,
            token: this.token,
            cardItemId: id,
          },
          false
        )
        .then((res) => {
          let data = res.data?.availableList ? res.data?.availableList[0] : {}
          this.voucherList = res.data?.availableList || [] // 可用列表
          this.unavailableList = res.data?.unavailableList || [] // 不可用列表
          // 如果有首约价就不选择优惠券
          // if (this.goodsPriceStatusMap[this.selectItem.id].discountType === 'FIRST_BUY') {
          //   this.selectedDiscount = 0
          //   this.selectedCouponId = ''
          //   return false
          // }
          this.selectedDiscount = data?.discount || 0
          // this.expireDayTime = data?.expireDayTime || 0
          this.selectedCouponId = data?.id || ''
          // let time = Math.round(data?.expireDayTime * 1000 - new Date().getTime()) || 0
        })
    },
    startUse() {
      this.isSuccessShow = false
      this.getShop()
      this.$router.push(`/training-record/vip?id=${this.shopId}`)
    },
    // 商城配置商品
    getShop() {
      this.$axios
        .post(`${this.baseURLApp}/activityShopWeb/getShop`, {
          shopId: this.shopId,
          userId: this.userId,
        })
        .then(async (res) => {
          this.AI = res.data
          await this.getMaxDiscount()
          this.selectCard(res.data.partitions.subfieldsInfos[0].groupInfos[0].goodsInfos[0])

          if (this.goodsPriceStatusMap[this.selectItem.id].discountType === 'FIRST_BUY') {
            this.isNewSaleShow = true
            this.newSaleCountdown()
          }
        })
    },
    // 最大优惠额
    getMaxDiscount() {
      const idArr = []
      this.AI.partitions.subfieldsInfos[0].groupInfos.map((group) => {
        group.goodsInfos.map((goods) => {
          idArr.push(goods.id)
        })
      })
      const params = {
        goodsId: idArr,
        shopId: this.shopId,
        userId: this.userId,
      }
      return this.$axios.post(`${this.baseURLApp}/activityShopWeb/getExerciseVipGoodMaxDiscount`, params).then((res) => {
        this.goodsPriceStatusMap = res.data.goodsDiscountMap
        this.isRedbagShow = res.data.showPop
      })
    },
    getBaseInfo() {
      let toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
        message: '加载中...',
        loadingType: 'spinner',
      })
      this.$axios
        .post(this.baseURLApp + '/c/user/equity/card/mall', {
          userId: this.userId,
        })
        .then((r) => {
          this.baseInfo = r.data
          this.introduceList = r.data.introduceList
          this.userInfo = r.data.user
          toast.clear()
        })
        .catch((r) => {
          // this.AI = { partitions: {} }
          // this.groupInfos = []
        })
    },
    payComplete(v) {},
    async selectCard(v) {
      this.selectItem = v
      this.getVoucherList(v.itemInfos[0].productId) // 请求用户的优惠券
    },

    // 校验是否可购买
    async checkAwaitOrder() {
      if (!this.protocol) {
        this.shake = true
        this.$toast({
          message: '请仔细阅读并勾选购买协议！',
          forbidClick: true,
          onClose: () => {
            this.shake = false
          },
        })
        return
      }

      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      this.$axios
        .post(
          `${this.baseURLApp}/c/user/equity/card/payCheck  `,
          {
            userId: this.userId,
          },
          false
        )
        .then((res) => {
          this.$axios
            .post(this.baseURLApp + '/activityShopOrder/checkBuy', {
              goodsId: this.selectItem.id,
              goodsItemId: this.selectItem.itemInfos[0].id,
              shopId: this.shopId,
              userId: this.userId,
            })
            .then((r) => {
              this.$toast.clear()
              this.buyCard()
            })
            .catch(() => {
              this.$toast.clear()
            })
        })
        .catch((r) => {
          this.errorMsg = { msg: r.msg }
          this.showError = true
          this.$toast.clear()
        })
    },

    // 购买
    buyCard() {
      // 点击购买页 上报

      let info = this.selectItem
      // let paramUrl = getParam().url
      // localStorage.setItem('paramUrl', paramUrl)
      let url = `/pages/webView/index?webUrl=${window.location.origin}/#/training-record/mall&userId=1`
      let payData = {
        subject: info.goodsName,
        extendsJson: {
          appVersion: '3.61.10',
          seriesName: info.goodsName,
          shopId: this.shopId,
          activityShopGoodsId: info.id,
          activityShopGoodsItemId: info.itemInfos[0].id,
          sourceChannel: this.channelCode || '',
        },
        // orderEquityCardCreateReq: {
        //   source: 'SELF_BUY',
        // },
        totalAmount: this.totalAmount,
        firstPrice: this.goodsPriceStatusMap[this.selectItem.id].discountType === 'FIRST_BUY' ? this.totalAmount : undefined,
        venueId: '31',
        goodsNum: '1',
        isApp: '1',
        type: this.selectItem.goodsType,
        selectedDiscount: this.goodsPriceStatusMap[this.selectItem.id].discountType === 'FIRST_BUY' ? '' : this.selectedDiscount,
        discountId: this.goodsPriceStatusMap[this.selectItem.id].discountType === 'FIRST_BUY' ? '' : this.selectedCouponId,
        userId: this.userId,
        token: this.token,

        extendsType: '4',
        mouldId: info.itemInfos[0].productId,
        whetherPrivacy: 0,
        originalPriceStr: info.salePrice,
        title: '购买内容',
      }
      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      }
      this.payData = payData
      this.payUrl = url

      this.showPayPopup = true

      if (this.appTypeStr !== 'mini') {
        appPaySuccess().then((res) => {
          this.isSuccessShow = true
        })
      }
    },
    exchangeConfirm() {
      this.$axios
        .post(this.baseURLApp + '/cdKey/activateCdKey/v2', {
          userId: this.userId,
          token: this.token,
          cdKey: this.cdKey,
          venueId: this.venueId,
          cityId: this.cityId,
        })
        .then((r) => {
          this.$toast.clear()
          this.isConfirmShow = false
          this.isSuccessShow = true
        })
        .catch((r) => {})
    },
    exchangeCheck(v) {
      this.cdKey = v
      if (!this.cdKey) {
        this.$toast({
          message: '请输入兑换码',
          forbidClick: true,
        })
        return
      }
      this.$axios
        .post(this.baseURLApp + '/cdKey/efficacyCdKey', {
          userId: this.userId,
          token: this.token,
          cdKey: this.cdKey,
          venueId: this.venueId,
          cityId: this.cityId,
        })
        .then((r) => {
          this.exchangeConfirm()
        })
        .catch((r) => {})
    },
    showEquityPopup(item, index) {
      this.isEquityShow = true
      this.equityIndex = index
    },
    showCouponPopup() {
      if (this.goodsPriceStatusMap[this.selectItem.id].discountType === 'FIRST_BUY') return
      this.isCouponShow = true
    },
    goRecord() {
      appOpenWeb('', `${window.location.origin}/#/training-record/vip-use-list`)
    },
    goArticle(type, title) {
      appOpenWeb(title, `${window.location.origin}/#/training-record/article?type=${type}`)
    },
    newClose() {
      this.isNewSaleShow = false
      setTimeout(() => {
        this.isNewAni = 'ani'
      }, 1000)
    },
    replaceDot(str) {
      if (!str) return []
      return str.split(',')
    },
    newSaleCountdown() {
      this.timer = setTimeout(() => {
        this.closeTime--
        this.newSaleCountdown()

        if (this.closeTime === 0) {
          clearTimeout(this.timer)
          this.newClose()
        }
      }, 1000)
    },
  },
}
</script>
<style lang="less" scoped>
.share-icon {
  width: 38px;
}

.page-box {
  width: 100%;
  min-height: 100vh;
  background: #1a1a1a;
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }
  & > div {
    width: 100%;
  }
  .main {
    overflow: hidden;
    padding: 0 24px 24px;
  }
  .head-bar {
    background: #1a1a1a;
    position: sticky;
    top: 0;
    color: #fff;
    padding: 18px 32px 18px 20px;
    z-index: 2;
    .back-box {
      width: 56px;
      height: 56px;
      background: rgba(0,0,0,0.3);
      border-radius: 50%;
    }
    .user {
      margin: 0 0 0 16px;
      .avator-box {
        position: relative;
        margin: 0 24px 0 0;
        .avator {
          width: 76px;
          height: 76px;
          display: block;
          border-radius: 50%;
        }
        .tag {
          width: 28px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      .p1 {
        margin: 0 0 6px;
      }
      .p2 {
        color: rgba(255, 255, 255, 0.8);
      }
      .p3 {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  .vip-box {
    margin: 20px 0 0;
    width: 100%;
  }

  .card-box {
    background-color: white;
    padding: 24px 32px 32px;
    border-radius: 20px;
    position: relative;
    margin: 24px 0 0;

    .card-title {
      .p1 {
        color: #6c727a;
      }
    }
    .card-list {
      margin: 46px 0 0;

      .card-item {
        width: 186px;
        padding: 24px 0 0;
        border-radius: 16px;
        border: 2px solid #d9d9d9;
        margin: 0 0 0 16px;
        flex: none;
        position: relative;
        &.forever {
          flex: auto;
          margin: 0;
        }
        &.active {
          border: 3px solid #26d548;
          background: #f0feed;
          .tag {
            color: #fff;
            background: #26d548;
          }
        }
        .tag {
          padding: 0 16px;
          height: 36px;
          color: #649075;
          background: #e4fae5;
          border-radius: 7px 7px 7px 0px;
          position: absolute;
          top: -24px;
          left: -2px;
        }
        .card-name {
          width: 100%;
          padding: 0 26px;
          margin: 0 0 8px;
        }

        .item-tips {
          color: #6c727a;
          padding: 0 26px;
          line-height: 1.2;
        }
        .price {
          padding: 0 26px;
          margin: 24px 0 0;
          .dis-price {
            color: #f03c18;
            white-space: nowrap;
            .dis-1 {
              position: absolute;
              left: 0;
              top: -100%;
              opacity: 0;
              &.ani {
                animation: fadeIn 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
              }
            }
            .dis-2 {
              &.ani {
                animation: fadeOut 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
              }
            }
          }

          .ori-price {
            text-decoration: line-through;
            color: #6c727a;
            transform: translateY(-12px);
            margin: 0 0 0 6px;
          }
        }
        .limit {
          width: 100%;
          height: 46px;
          background: #e4fae5;
          border-radius: 0px 0px 15px 15px;
          color: #649075;
          .limit-icon {
            width: 26px;
            margin: 0 6px 0 0;
          }
          .van-count-down {
            font-size: 20px;
            color: #649075;
          }
        }
        .sj-icon {
          width: 26px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -31px;
        }
      }
    }
    .card-tips {
      width: 100%;
      height: 66px;
      padding: 0 18px;
      background: rgba(255, 251, 223, 1);
      border-radius: 12px;
      border: 1px solid rgba(240, 60, 24, 0.15);
      margin: 26px 0 0;
      .icon {
        width: 36px;
        margin: 0 14px 0 0;
      }
    }
  }
  .equity-box {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px 20px 20px 20px;
    padding: 28px 32px 32px;
    margin: 24px 0 0;
    .equity-list {
      margin: 28px 0 0;
      display: flex;
      justify-content: space-between;
    }
    .equity-item {
      width: 308px;
      margin: 0 0 22px;
      border-radius: 16px;
    }
  }
  .rule-box {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    margin: 24px 0 0;
    .rule-item {
      color: #fff;
      height: 100px;
      padding: 0 32px;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      }
    }
  }
  .bottom-btn {
    width: 100%;
    background-color: #313131;
    backdrop-filter: blur(13px);
    position: sticky;
    left: 0;
    bottom: 0;
    padding: 24px 24px calc(12px + env(safe-area-inset-bottom));
    z-index: 999;

    .select-voucher {
      width: 100vw;
      height: 72px;
      background: #fdeeec;
      margin: 0 auto 0;
      padding: 0 32px;

      img {
        height: 32px;
        margin-right: 12px;
      }

      & > div {
        span {
          color: #f03c18;
        }
      }

      & > p {
        span {
          margin-left: 4px;
        }
      }
    }

    .buy-card-btn {
      width: 100%;
      height: 104px;
      padding: 0 8px 0 56px;
      background: #000000;
      border-radius: 16px;
      color: #fff;
      .btn {
        color: #002c09;
        width: 328px;
        height: 88px;
        background: linear-gradient(90deg, #9bff78 0%, #2ee251 100%);
        border-radius: 8px;
      }
      .tag {
        padding: 0 10px;
        height: 36px;
        background: #f03c18;
        border-radius: 4px;
        margin: 0 0 0 16px;
      }
    }

    .protocol {
      margin: 16px 0 0;

      &.shake {
        animation: shake 1s;
      }

      p {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        background: url('https://img.chaolu.com.cn/ACT/invite-2022/icon7.png') no-repeat center center;
        background-size: 100% 100%;

        &.active {
          background-image: url('https://img.chaolu.com.cn/ACT/invite-2022/icon6.png');
        }
      }

      div {
        color: rgba(255, 255, 255, 0.7);
        margin: 4px 0 0;
        .s1 {
          color: #fff;
        }
      }
    }
  }
}
.new-popup {
  width: 426px;
  margin: 0 auto;
  .red-bag {
    width: 426px;
    height: 564px;
    margin: 34px 0 0;
    padding: 64px 0 0;
    background: url(https://img.chaolu.com.cn/ACT/training-record/202410/red-bag.png);
    background-size: 100% auto;
    .p1 {
      color: #f03c18;
    }
    .s1 {
      font-size: 52px;
    }
    .s2 {
      font-size: 88px;
    }
    .limit {
      width: 214px;
      height: 48px;
      margin: 118px 0 0;
      color: #fff4d4;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px 8px 8px 8px;
      border: 1px solid rgba(255, 250, 204, 0.6);
    }
    .p2 {
      color: #fff;
      margin: 32px 0 0;
    }
    .button {
      width: 268px;
      margin: 30px 0 0;
    }
  }
  .van-icon {
    align-self: flex-end;
  }
  .p3 {
    color: #fff;
    margin: 28px 0 0;
  }
}
.success-popup {
  width: 580px;
  padding: 196px 0 62px;
  margin: 0 auto;
  background: url(https://img.chaolu.com.cn/ACT/training-record/202410/3.png), linear-gradient(180deg, #262626 50%, #151515 100%);
  background-size: 100% auto;
  background-repeat: no-repeat;
  border-radius: 24px 24px 24px 24px;
  .avator {
    width: 188px;
    height: 188px;
    border-radius: 50%;
  }
  .p1 {

    margin: 38px 0 0;
    background: linear-gradient(52.515579821163215deg, #FFFDE0 0%, #F8FCFB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .p2 {
    color: rgba(255, 255, 255, 0.8);
    margin: 26px 0 0;
  }
  .button {
    width: 316px;
    height: 88px;
    margin: 46px 0 0;
    background: linear-gradient(90deg, #9bff78 0%, #2ee251 100%), #ffde00;
    border-radius: 12px;
  }
}

.error-box {
  width: 630px;
  background: #ffffff;
  border-radius: 12px;
  margin: 0 auto;
  padding-bottom: 48px;

  & > span {
    display: block;
    text-align: center;
    margin-bottom: 24px;
    font-size: 28px;
    font-weight: bold;
    color: #0a7aff;
  }

  h4 {
    font-size: 36px;
    font-weight: 600;
    color: #242831;
    line-height: 36px;
    text-align: center;
    padding-top: 58px;
  }

  p {
    margin: 32px auto 56px;
    width: 510px;
    font-size: 28px;
    font-weight: 400;
    color: #3c454e;
    line-height: 44px;
  }
  .p2 {
    color: #ff6e00;
    margin: 0 auto 12px;
  }
  .btn {
    margin: 0 auto;
    font-weight: bold;
    width: 510px;
    height: 96px;
    background: #ffde00;
    border-radius: 8px;
    font-size: 32px;
    color: #242831;
    line-height: 32px;
  }
}

.pay-share-btn {
  div {
    width: 100%;
    height: 96px;
    border-radius: 8px;
    border: 1px solid #ffde00;
    background: #ffde00;
  }

  .grey-btn {
    width: 206px;
    flex: none;
    margin: 0 24px 0 0;
    background-color: #fff;
    border: 1px solid #cccccc;
  }

  padding: 0 32px;
  height: 96px;
  margin: 48px auto 0;
  color: #242831;
}

@keyframes fadeIn {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(100%);
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

/deep/.van-swipe__indicators {
  bottom: -32px;
  .van-swipe__indicator {
    background-color: #ddd;
    opacity: 1;
  }
}
/deep/ .red {
  color: #f03c18;
}
.white {
  color: #fff;
}
.green {
  color: #26d548;
}
</style>
