<template>
  <div>
    <nav-bar :headerTitle="detail.templateName" ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container">
      <!-- 头部 -->
      <div class="banner-box">
        <img :src="detail.detailBackgroundImage" alt="" class="banner" />
        <div class="mask row-start-end">
          <h2 class="f54 fw6">{{ detail.templateName }}</h2>
        </div>
      </div>
      <p v-if="detail.isDel" class="del-tip f32 fw6">该模板已被删除</p>
      <!-- 计划概览 -->
      <div class="info-box f28">
        <div class="info-item row-start-center">
          <img src="https://img.chaolu.com.cn/ACT/training-record/1-icon.png" alt="" class="icon" />
          <p class="p2">{{ detail.motionNum }}个动作 · {{ detail.groupNum }}组 · {{ detail.sumKg }}kg</p>
        </div>
        <div class="info-item row-start-center">
          <img src="https://img.chaolu.com.cn/ACT/training-record/yalin-icon.png" alt="" class="icon" />
          <p class="p2" v-for="utensil in detail.utensilNameList" :key="utensil">{{ utensil }}</p>
        </div>
      </div>
      <!-- 计划详情 -->
      <div class="course-box" v-if="detail.muscleModel">
        <muscle :muscleModel="detail.muscleModel"></muscle>
        <!-- <muscle class="body-con" :mainMuscleList="groupDetail.mainMuscleList" :secondaryMuscleList="groupDetail.secondaryMuscleList"></muscle> -->
      </div>
      <!-- 模板介绍 -->
      <div v-if="detail.introduce" class="course-box">
        <h3 class="f32 fw6">模板介绍</h3>
        <p class="introduce f26">{{ detail.introduce }}</p>
      </div>

      <!-- 计划课程 -->
      <div class="course-box">
        <h3 class="f32 fw6 row-start-center">动作列表 <img v-show="detail.relatedConfigAuth.vipStatus === 2" class="limit-icon" src="https://img.chaolu.com.cn/ACT/training-record/202410/limit.png" alt=""></h3>
        <div class="course-list">
          <div @click="jump(item)" class="course-item" v-for="item,index in detail.relatedConfigList" :key="index">
            <div class="course-item--top row-between-center">
              <p class="f40 BebasNeueBold">{{ index + 1 }}</p>
              <div class="c-poster flex-none" :style="`background-image: url(${item.image})`"></div>
              <div class="c-content flex-auto">
                <p class="f30">{{ item.name }}</p>
                <p class="p2 f24 opacity9">{{ item.labelStr }}</p>
              </div>
              <van-icon name="arrow" />
            </div>
            <!-- vip -->
            <img @click="downloadToast" v-if="detail.relatedConfigAuth.isLimit" :src="detail.relatedConfigAuth.overlayImg" alt="" style="width: 100%;">
            <!-- 非vip -->
            <div v-else class="course-item--bottom">
              <div v-if="item.motionMode === 'GROUP'" class="action-type">
                <p class="opacity9" v-for="group in item.group.motionInfoList" :key="group.motionCode"><span class="f22 fw6">{{ group.motionCode }}</span> <span class="f24">{{ group.motionName }}</span></p>
              </div>

              <div class="action-item row-between-start" v-for="motion,index in whichMode(item)" :key="index">
                <div class="flex">
                  <p class="index f20 row-center-center">{{ motion.groupName }}</p>
                  <div style="margin: -3px 0 0;">
                      <div class="row-start-end line-item" v-for="exercise,index in whichMotion(motion)" :key="index">
                        <p v-if="exercise.subGroupName" class="sub-index f22 opacity9 row-center-center">{{ exercise.subGroupName }}</p>
                        <!-- 模板A 包含单手、双手两种 -->
                        <template v-if="whichExercise(exercise).templateName === 'A'">
                          <p v-if="whichExercise(exercise).isUseOneHand"><span class="f28 fw6">({{ whichExercise(exercise).leftWeight }}&nbsp;<span class="f22 fw4">+</span>&nbsp;{{ whichExercise(exercise).rightWeight }})</span><span class="f20 opacity9">{{ whichExercise(exercise).unit }}</span></p>
                          <p v-else><span class="f28 fw6">{{ whichExercise(exercise).weight }}</span><span class="f20 opacity9">{{ whichExercise(exercise).unit }}</span></p>
                          <p class="f22">&nbsp;&nbsp;×&nbsp;&nbsp;</p>
                          <p class="row-start-end"><span class="f28 fw6">{{ whichExercise(exercise).count }}</span><span class="f20 opacity9">次</span></p>
                        </template>

                        <!-- 模板B -->
                        <template v-if="whichExercise(exercise).templateName === 'B'">
                          <p><span class="f28 fw6">{{ whichExercise(exercise).distance }}</span><span class="f20 opacity9">{{ whichExercise(exercise).unit }}</span></p>
                          <p class="f22">&nbsp;&nbsp;&nbsp;&nbsp;</p>
                          <p class="row-start-end"><span class="f28 fw6">{{ resolveNum(whichExercise(exercise).hour) }}</span>:<span class="f28 fw6">{{ resolveNum(whichExercise(exercise).minute) }}</span>:<span class="f28 fw6">{{ resolveNum(whichExercise(exercise).seconds) }}</span></p>
                        </template>

                        <!-- 模板C -->
                        <template v-if="whichExercise(exercise).templateName === 'C'">
                          <p class="row-start-end"><span class="f28 fw6">{{ whichExercise(exercise).count }}</span><span class="f20 opacity9">次</span></p>
                        </template>

                        <!-- 模板D -->
                        <template v-if="whichExercise(exercise).templateName === 'D'">
                          <p class="row-start-end"><span class="f28 fw6">{{ resolveNum(whichExercise(exercise).hour) }}</span>:<span class="f28 fw6">{{ resolveNum(whichExercise(exercise).minute) }}</span>:<span class="f28 fw6">{{ resolveNum(whichExercise(exercise).seconds) }}</span></p>
                        </template>

                        <!-- 模板E -->
                        <template v-if="['E1', 'E2'].includes(whichExercise(exercise).templateName)">
                          <p><span class="f28 fw6">{{ whichExercise(exercise).weight }}</span><span class="f20 opacity9">{{ whichExercise(exercise).unit }}</span></p>
                          <p class="f22">&nbsp;&nbsp;×&nbsp;&nbsp;</p>
                          <p class="row-start-end"><span class="f28 fw6">{{ whichExercise(exercise).count }}</span><span class="f20 opacity9">次</span></p>
                        </template>

                        <!-- 模板F -->
                        <template v-if="whichExercise(exercise).templateName === 'F'">
                          <p class="row-start-end"><span class="f28 fw6">{{ whichExercise(exercise).loopCount }}</span><span class="f20 opacity9">组</span></p>
                          <p class="f22">&nbsp;&nbsp;×&nbsp;&nbsp;</p>
                          <p class="row-start-end"><span class="f28 fw6">{{ whichExercise(exercise).singleSecond }}</span><span class="f20 opacity9">秒</span></p>
                          <p class="f22">&nbsp;&nbsp;×&nbsp;&nbsp;</p>
                          <p class="row-start-end"><span class="f28 fw6">{{ whichExercise(exercise).intervalSecond }}</span><span class="f20 opacity9">秒/组休息</span></p>
                        </template>

                      </div>
                    </div>

                </div>
                <p v-if="motion.restPeriod" class="f20 opacity9">{{ motion.restPeriod }}s</p>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <go-introduction-button />
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar-202210/index'
import muscle from './components/muscle'
import goIntroductionButton from './components/go-introduction-button'
import { newAppBack, initBack, appOpenWeb, loginOut } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'

export default {
  mixins: [userMixin],
  components: {
    navBar,
    muscle,
    goIntroductionButton
  },
  data() {
    return {
      detail: {},
      templateId: ''
    }
  },
  computed: {
    whichMode() {
      return function(item) {
        if(item.motion || item.group) {
          return item.motion ? item.motion.configGroupList : item.group.configGroupList
        }
        return []
      }
    },
    whichMotion() {
      return function(motion) {
        return motion.commonExerciseConfigTemplateList ? motion.commonExerciseConfigTemplateList : motion.exerciseConfigTemplateList
      }
    },
    whichExercise() {
      return function(exercise) {
        const e = exercise.commonExerciseConfigTemplate ? exercise.commonExerciseConfigTemplate : exercise
        return e
      }
    },
    resolveNum() {
      return function(n) {
        return n < 10 ? `0${n}` : n
      }
    }
  },
  async created() {
    // 初始化
    await this.$getAllInfo()
    initBack()
    // ------
    if (!this.userId) {
      loginOut()
      return
    }

    this.templateId = getParam().templateId
    this.countPoint('500', '500', '5018', this.userId, this.templateId)

    this.getDetail()
  },

  methods: {
    newAppBack,
    getDetail() {
      this.$axios.post(this.baseURLApp + '/c/template/shareDetail ', {
        templateId: this.templateId,
        userId: this.userId
      }).then((res) => {
        this.detail = res.data
        document.title = this.detail.templateName
      })
    },
    jump(item) {
      if (this.detail.relatedConfigAuth.isLimit) {
        this.downloadToast()
        return
      }
      appOpenWeb(item.name, `${window.location.origin}/#/training-record/${ item.motionMode === 'MOTION' ? 'motion' : 'super-group' }?${ item.motionMode === 'MOTION' ? 'motionId' : 'groupId' }=${item.relatedId}&title=${item.name}`)
    },
    downloadToast() {
      this.$toast('可下载APP体验所有功能权益')
    }
  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  min-height: 100vh;
  background: #242424;
  color: #fff;
  * {
    box-sizing: border-box;
  }
  width: 100%;
  padding: calc(env(safe-area-inset-top)) 0 calc(140px + env(safe-area-inset-bottom));
  .banner-box {
    position: relative;
    .banner {
      width: 100%;
    }
    .mask {
      background: linear-gradient(180deg, rgba(36, 36, 36, 0) 0%, #242424 100%);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 1;
      padding: 0 0 48px 40px;
    }
  }
  .del-tip {
    margin: 50px 0 84px;
    text-align: center;
    color: #F03C18;
  }
  .info-box {
    position: relative;
    z-index: 2;
    padding: 0 40px;
    .info-item {
      margin: 34px 0 0;
      .icon {
        width: 30px;
        height: 30px;
        margin: 0 16px 0 0;
      }
      .p2 {
        margin: 0 12px 0 0;
      }
    }
  }
  .course-box {
    padding: 0 40px;
    margin: 90px 0 0;
    .limit-icon {

width: 60px;
margin: 0 0 0 12px;
}
    .introduce {
      margin: 40px 0 0;
    }
    .course-list {
      margin: 28px 0 0;
      .course-item {
        margin: 20px 0 0;
        padding: 28px 32px;
        background: #303030;

        border-radius: 12px;
        .c-poster {
          width: 144px;
          height: 144px;
          border-radius: 10px;
          background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/default-banner.png);
          background-size: cover;
          background-position: center;
          position: relative;
          overflow: hidden;
          margin: 0 0 0 32px;
        }
        .c-content {
          margin: 0 26px 0;
          .p2 {
            margin: 12px 0 0;
          }
        }

        .course-item--bottom {
          padding: 0 0 0 52px;
          margin: 48px 0 0;
          .action-item {
            margin: 28px 0 0;
            & > div {
              margin: -6px 0 0;
            }
            .line-item {
              margin: 0 0 12px;
            }
            .index {
              width: 30px;
              height: 30px;
              background: rgba(255, 255, 255, 0.15);
              border-radius: 50px 50px 50px 50px;
              margin: 0 14px 0 0;
            }
            .sub-index {
              margin: 0 14px 0 0;
            }
          }
        }
      }
    }
  }
  .opacity9 {
    opacity: 0.9;
  }
}
</style>
