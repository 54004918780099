<template>
  <div class="container">
    <div class="nav row-between-center">
      <div class="row-start-center">
        <img class="logo" src="https://img.chaolu.com.cn/MINI/coach-feelings/logo.png" alt="" />
        <div>
          <p class="f28 fw6">超鹿运动App</p>
          <p class="f22 grey">可按次付费的健身房</p>
        </div>
      </div>
      <div class="open-button row-center-center fw6 f24">
        打开App
        <!-- <wx-open-app :extinfo="extinfo"></wx-open-app> -->
        <wx-open-launch-weapp class="wexin-launch-btn" id="launch-btn" appid="wx1c0625c842d733d5" :env-version="env" :path="`pages/webView/index?webUrl=${locationUrl}/#/training-record/introduction&exerciseShareSource=KEY_WORD&channelCode=drill_plan`">
          <script type="text/wxtag-template">
            <style>.btn {width:100%;height:65px;background: #fff}</style>
            <button class="btn">打开小程序</button>
          </script>
        </wx-open-launch-weapp>
      </div>
    </div>

    <div class="main row-between-start flex-wrap">
      <div @click="jump(item.motionId)" class="item" v-for="(item, index) in motionList" :key="index">
        <img :src="item.image" alt="" />
        <p class="f28">{{ item.name }}</p>

        <wx-open-launch-weapp
          v-if="appTypeStr === 'wx'"
          class="wexin-launch-btn"
          :id="`launch-btn${item.id}`"
          appid="wx1c0625c842d733d5"
          :env-version="env"
          :path="`pages/webView/index?webUrl=${locationUrl}/#/training-record/motion&motionId=${item.motionId}&channelCode=drill_plan&exerciseShareSource=KEY_WORD`">
          <script type="text/wxtag-template">
            <style>.btn {position: absolute; top: 0; left: 0; width:100%; height:100%;}</style>
            <button class="btn">打开小程序</button>
          </script>
        </wx-open-launch-weapp>
      </div>
    </div>
  </div>
</template>

<script>
import { getParam } from '@/lib/utils'
import { openApp } from '@/lib/wx-sdk'

export default {
  components: {},
  // 3014 tab训记页  加一个index 0概要1日历2动作3模板4计划5数据  2的情况searchName 有的话是搜索内容
  data() {
    return {
      searchName: '',
      motionList: [],
      extinfo: {
        type: '3014',
        index: '2',
        searchName: '杰尼龟搜索内容',
      },
    }
  },
  computed: {
    env() {
      return this.locationUrl === 'https://web.chaolu.com.cn' ? 'release' : 'trial'
    },
  },
  async mounted() {
    this.searchName = decodeURIComponent(getParam().searchName)
    this.getMotionList()

    this.extinfo.searchName = this.searchName
    this.extinfo = JSON.stringify(this.extinfo)
    openApp()

    document.title = this.searchName
  },

  methods: {
    getMotionList() {
      this.$axios
        .post(this.baseURLApp + '/c/motion/authorityMotionSearch', {
          searchName: this.searchName,
        })
        .then((res) => {
          this.motionList = res.data
        })
    },
    jump(id) {
      if (this.appTypeStr == 'wx') return
      this.$router.push(`/training-record/motion?motionId=${id}`)
    },
  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  background: #f5f5f5;
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }

  .nav {
    width: 750px;
    height: 128px;
    background: #ffffff;
    border-bottom: 1px solid #eeeeee;
    padding: 0 36px;
    position: sticky;
    z-index: 9;
    top: 0;
    .logo {
      width: 76px;
      margin: 0 20px 0 0;
    }
    .open-button {
      width: 146px;
      height: 56px;
      background: #ffde00;
      border-radius: 4px 4px 4px 4px;
    }
  }
  .main {
    padding: 32px;
    .item {
      width: 330px;
      height: 426px;
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      margin: 0 0 28px;
      position: relative;
      padding: 16px;
      text-align: center;
      img {
        width: 100%;
        height: 298px;
        object-fit: contain;
      }
      p {
        margin: 12px 0 0;
      }
    }
  }
  .wexin-launch-btn {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    z-index: 1;
  }
}
.grey {
  color: #6c727a;
}
</style>
