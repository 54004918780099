<template>
  <div>
    <nav-bar ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container">
      <!-- 头部 -->
      <div class="banner-box">
        <h2 class="f54 fw6">{{ groupDetail.name }}</h2>
      </div>

      <!-- 动作列表 -->
      <div class="course-box">
        <h3 class="f32 fw6">动作列表</h3>
        <div class="course-list">
          <div @click="jump(item)" v-for="item in groupDetail.motionList" :key="item.motionId" class="course-item row-between-center">
            <div class="c-poster flex-none" :style="`background-image: url(${item.image})`"></div>
            <div class="c-content flex-auto col-center-start">
              <p class="f30 ellipsis">{{ item.name }}</p>
              <div class="line-1 f24 flex ellipsis-2">
                <p class="p1" v-html="item.labelColorStr"></p>
              </div>

            </div>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <!-- 计划课程 -->
      <div class="course-box">
        <h3 class="f32 fw6">训练部位</h3>
        <muscle class="body-con" :muscleModel="groupDetail.muscleModel"></muscle>
      </div>
    </div>
    <go-introduction-button />
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar/nav-bar'
import muscle from './components/muscle'
import goIntroductionButton from './components/go-introduction-button'
import { newAppBack, initBack, appOpenWeb, loginOut } from '@/lib/appMethod'
import { getParam, getRedString } from '@/lib/utils'

export default {
  mixins: [userMixin],
  components: {
    navBar,
    muscle,
    goIntroductionButton
  },
  data() {
    return {
      groupDetail: {}, // 总详情
    }
  },
  computed: {},
  async created() {
    // 初始化
    await this.$getAllInfo()
    initBack()
    // ------
    if (!this.userId) {
      loginOut()
      return
    }

    // 获取链接参数
    this.params = getParam()
    this.params = {
      groupId: this.params.groupId || 12,
      userId: this.userId
    }
    this.countPoint('500', '500', '5017', this.userId, this.params.groupId)

    this.getMotion()
  },

  methods: {
    newAppBack,

    getMotion() {
      this.$axios.post(this.baseURLApp + '/c/user/motion/group/shareDetail', this.params).then((res) => {
        this.groupDetail = res.data
        document.title = res.data.name
        // 改颜色
        this.groupDetail.motionList.map((item) => {
          item.labelColorStr = item.highlightLabelList.reduce((pre, label) => {
            return getRedString(pre, label)
          }, item.labelStr)
        })
      })
    },
    jump(item) {
      appOpenWeb(item.name, `${window.location.origin}/#/training-record/motion?motionId=${item.motionId}&title=${item.name}`)
    }
  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  min-height: 100vh;
  background: #242424;
  color: #fff;
  * {
    box-sizing: border-box;
  }
  width: 100%;
  padding: calc(env(safe-area-inset-top)) 0 calc(140px + env(safe-area-inset-bottom));
  .banner-box {
    position: relative;
    padding: 64px 0 0 40px;

  }
  .info-box {
    position: relative;
    z-index: 2;
    padding: 0 40px;
    margin: -28px 0 0;
    .info-item {
      width: 213px;
      height: 136px;
      padding: 18px 0 0 28px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 12px 12px 12px 12px;
      .p2 {
        margin: 18px 0 0;
      }
    }
  }
  .course-box {
    padding: 0 40px;
    margin: 90px 0 0;
    .body-con {
      margin: 40px 0 0;
    }
    .course-list {
      margin: 12px 0 0;
      .course-item {
        margin: 36px 0 0;
        padding: 24px;
        background: rgba(255, 255, 255, 0.07);
        border-radius: 12px;
        .c-poster {
          width: 144px;
          height: 144px;
          box-shadow: 0px 2 9px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;

          background-size: cover;
          background-position: center;
          position: relative;
        }
        .c-content {
          margin: 0 26px 0;
          overflow: hidden;
          .line-1 {
            margin: 16px 0 0;
          }
          .p1 {
            margin: 0 24px 0 0;
            line-height: 32px;
            white-space: pre-line;
          }

        }
      }
    }
  }
  .opacity9 {
    opacity: 0.9;
  }
  /deep/.red {
    color: #FFDE00;
  }
}
</style>
