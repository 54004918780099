// app启动屏中转页
<template>
  <div class="page">
    <nav-bar headerTitle="训记用户内测" ref="head" :showBack="true" :headerBack="newAppBack"></nav-bar>

    <img class="start-img" :src="qrcode" alt="">

    <div @click="save" class="btn f32 fw6 row-center-center">保存图片</div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar/nav-bar'
import { newAppBack, saveCreateImg } from '@/lib/appMethod'

export default {
  data() {
    return {
      qrcode: 'https://img.chaolu.com.cn/ACT/training-record/202410/community-qrcode.png'
    };
  },

  mixins: [userMixin],
  components: {
    navBar,
  },
  async created() {

  },
  methods: {
    newAppBack,
    save() {
      saveCreateImg(this.qrcode)
    },


  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  position: relative;
  min-height: 100vh;
  padding: calc(132px + env(safe-area-inset-top)) 40px 0;
  background-color: #F5F5F5;
  * {
    box-sizing: border-box;
  }

  .start-img {
    width: 100%;
  }
  .btn {
    position: fixed;
    bottom: calc(32px + env(safe-area-inset-bottom));
    left: 50%;
    transform: translate(-50%);
    z-index: 1000;
    width: 686px;
    height: 96px;
    background: #FFDE00;
    border-radius: 8px 8px 8px 8px;
  }

}</style>
