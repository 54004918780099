import wx from 'weixin-js-sdk'
import ajax from '../config/requestUtil.js'

function getConfig() {
  const baseURL = window.location.href.indexOf('web.chaolu') > -1 ? 'https://app.chaolu.com.cn' : 'http://apptest.chaolu.com.cn'
  return new Promise((resolve, reject) => {
    ajax.post(`${baseURL}/wechat/mp/jsSdkConfig`, {
      url: window.location.href.split('#')[0]
    }).then(res => {
      resolve(res.data)
    })
  })

}

export async function openApp() {
  const _config = await getConfig()
  wx.config({
    debug: false,
    appId: _config.appId,
    timestamp: _config.timestamp,
    nonceStr: _config.nonceStr,
    signature: _config.signature,
    jsApiList: ['updateAppMessageShareData'],
    openTagList: ['wx-open-launch-app', 'wx-open-launch-weapp']
  })
  wx.ready(function() {

  })
  wx.error(function(res) {

  })
}

export async function wxShare(params) {
  const _config = await getConfig()
  wx.config({
    debug: true,
    appId: _config.appId,
    timestamp: _config.timestamp,
    nonceStr: _config.nonceStr,
    signature: _config.signature,
    jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage']
  })
  const realUrl = window.location.href;
  params = Object.assign(params, { link: realUrl });
  changeWxShare(params);
}

export function changeWxShare(params) {
  wx.ready(function () {
    wx.onMenuShareAppMessage({
      title: params.title, // 分享标题
      desc: params.desc, // 分享描述
      link: params.link, // 分享链接
      imgUrl: params.imgUrl, // 分享图标
      type: 'link', // 分享类型,music、video或link，不填默认为link
      dataUrl: '',
      success: function () {

      },
    });

    wx.onMenuShareTimeline({
      title: params.title, // 分享标题
      link: params.link, // 分享链接
      imgUrl: params.imgUrl,
      success: function () {

      },
    });
  })
}

