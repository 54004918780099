<template>
  <div class="page-rooter-fff">
    <headeBar title="训记VIP开通记录" left-arrow @click-left="newAppBack" />
    <div class="page-content">
      <van-tabs border swipeable v-model="active" title-active-color="#242831" title-inactive-color="#6C727A" color="#242831" line-height="0.53vw" line-width="42vw" @change="changeTab">
        <van-tab :title="item.title" :name="item.name" v-for="item in tabs" :key="item.title">
          <div class="list" v-if="item.name === param.status && recordList.length">
            <div class="box-item" v-for="(v) in recordList" :key="v.userEquityCardId">
              <div class="item" :style="`background-image: url(${v.cardImg})`">
                <div>
                  <div class="name">{{ v.cardName }}</div>
                </div>
                <div class="date-info row-start-end">
                  <div>
                    <p :style="{ color: v.status === 'ACTIVE' ? '#FFDE00' : '#FFFFFF' }">{{ v.statusName }}</p>
                  </div>
                  <div v-if="v.specText">
                    <p>{{ v.specText }}</p>
                  </div>

                  <van-popover v-model="v.show" trigger="click" overlay placement="bottom-start">
                    <div class="white-board f24">
                      <p>获得时间：{{ v.getDate }}</p>
                      <p>获得来源：{{ v.sourceText }}</p>
                      <p>生效期：{{ v.vipValidityText }}</p>
                    </div>
                    <template #reference>
                      <div>
                        <p>详情&nbsp;<van-icon name="arrow-down" /></p>
                      </div>
                    </template>
                  </van-popover>
                </div>
              </div>
            </div>
          </div>
          <div class="empty" v-else>
            <img src="https://img.chaolu.com.cn/MINI/icon/empty_icon.png" alt="" />
            <div>暂无可用</div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import headeBar from '@/components/app/headBar'

import wx from 'weixin-js-sdk'

export default {
  data() {
    return {
      tabs: [{title: '使用中', name: 'USE'}, {title: '已失效', name: 'EXPIRED'}],
      recordList: [],

      active: 0,
      equityCardUserId: '',
      cardType: 0, // 0 自助卡，  1 团课卡 2 私教
      param: {
        status: 'USE',
        userId: '',
      },
      showPopover: false,
    }
  },
  mixins: [userMixin],
  components: { headeBar },
  async created() {
    initBack() //劫持后退
    await this.$getAllInfo()
    this.param.userId = this.userId
    this.getRecord()
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.getRecord()
      }
    })
  },
  computed: {

  },
  methods: {
    changeTab(v) {
      this.param.status = v
      this.getRecord()
    },

    // 获取卡列表
    getRecord() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })

      this.$axios
        .post(`${this.baseURLApp}/c/user/equity/card/openRecord`, this.param)
        .then((res) => {
          this.recordList = res.data || []
          this.recordList.map((item) => {
            this.$set(item, 'show', false)
          })
        })
        .finally((r) => {
          this.$toast.clear()
        })
    },
    newAppBack,
  },
}
</script>

<style lang="less" scoped>
.page-rooter-fff {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .page-content {
    //flex-grow: 1;
    flex: 1 1 0;
    overflow: hidden;
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  min-height: 80vh;

  img {
    width: 320px;
    height: 320px;
  }

  div {
    text-align: center;
    margin-top: 20px;
    font-size: 26px;
    line-height: 40px;
    color: #6c727a;
  }

  p {
    background: url('https://img.chaolu.com.cn/ACT/icon/right_arrows.png') no-repeat 392px center #ffde00;
    background-size: 40px 40px;
    width: 460px;
    height: 94px;
    border-radius: 8px;
    margin-top: 64px;
  }
}

.list {
  height: 100%;
  width: 100%;
  padding: 20px 32px 150px;
  box-sizing: border-box;
  overflow-y: auto;

  .box-item + .box-item {
    margin-top: 32px;
  }

  .box-item {
    border-radius: 16px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    border: 1px solid #eee;
  }

  .item {
    &.expired {
      background-color: #eee;
    }

    width: 100%;
    height: 320px;
    display: flex;
    padding-left: 28px;
    padding-top: 24px;
    box-sizing: border-box;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .name {
      font-size: 44px;
      font-family: PuHuiTi;
      color: #ffffff;
      width: 8em;
      line-height: 52px;
      margin-bottom: 26px;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      -webkit-background-clip: text;
    }

    .date-info {
      position: absolute;
      bottom: 28px;
      left: 28px;
      width: 622px;
      div {
        flex: none;
      }
      p {
        color: #ffffff;
        font-weight: bold;
        height: 36px;
        font-size: 20px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.5);
        padding: 0 12px;
        display: flex;
        align-items: center;
        margin-right: 16px;
        &.blue {
          background: #1777ff;
        }
      }
    }
  }
}

/deep/ .van-tab--active {
  font-weight: bold;
}

/deep/ .van-tabs {
  height: 100%;
}

/deep/ .van-hairline--top-bottom::after {
  border-top: none;
  border-color: #eee;
}

/deep/ .van-tabs__content,
/deep/ .van-tab__pane {
  height: calc(100% - 44px);
  display: flex;
  flex-direction: column;
}
.white-board {
  padding: 32px;
  p:not(:last-child) {
    margin: 0 0 22px;
  }
}
</style>
