<template>
  <div>
    <nav-bar ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container">
      <!-- 头部 -->
      <div class="banner-box">
        <template v-if="backgroundVideo && isAndroid && !isPlaying">
          <img :src="backgroundVideo.coverUrl" alt="" class="banner" />
          <van-icon @click="playVideo" name="play-circle-o" class="play-icon" size="50" />
        </template>

        <!-- <video
          v-if="backgroundVideo && isPlaying"
          ref="backgroundVideo"
          class="video-js vjs-big-play-centered"
          playsinline="true"
          webkit-playsinline="true"
          muted
          loop
          autoplay
          x-webkit-airplay="true"
          x5-video-player-type="h5-page"
          x5-video-player-fullscreen="false"
          x5-video-orientation="portraint"
          style="object-fit: cover; width: 100%; height: 100%"
          :src="backgroundVideo.url" /> -->
        <div v-show="backgroundVideo" id="j_cole" style="height: 100vw"></div>
        <img @click="downloadToast('MAIN_VIDEO')" v-show="!backgroundVideo && backgroundImage" :src="backgroundImage" alt="" class="banner" />

        <div @click="downloadToast('MAIN_VIDEO')" class="mask row-start-end">
          <div class="mask-con">
            <div class="row-between-center">
              <!-- 真人非必填 正侧面非必填 -->
              <div v-if="motionDetail.backgroundMode === 1 && ((motionDetail.mainVideoList && motionDetail.mainVideoList.length) || motionDetail.mainVideoAuth.isLimit) && (motionDetail.threeDVideoPlay || motionDetail.threeDVideoAuth.isLimit)" class="check-btn row-center-center f22">
                <div @click="changePlayType(index)" :class="['check-item', 'row-center-center', typeIndex === index ? 'active fw6' : '']" v-for="(item, index) in checkTypes" :key="index">{{ item.name }}</div>
              </div>
              <div v-else></div>
              <!-- 背景模式为（3d+真人）且是真人tab的情况下才有 -->
              <div v-if="motionDetail.backgroundMode === 1 && typeIndex === 1" class="row-start-center">
                <!-- 只有其中一个不展示 -->
                <div v-if="motionDetail.mainVideoList && motionDetail.mainVideoList.length > 1" @click="changePlayIndex" class="check-btn row-center-center fw6 f22">
                  {{ backgroundVideoAngle.visualAngleName }} <img src="https://img.chaolu.com.cn/ACT/training-record/check-icon.png" alt="" class="icon" />
                </div>
                <div @click="changePlayRate" class="check-btn ml34 row-center-center fw6 f24">{{ playbackRate }} X</div>
              </div>
            </div>
            <h2 class="f54 fw6">{{ motionDetail.name }}</h2>
          </div>
        </div>

        <!-- 限免标识 -->
        <img v-show="(typeIndex === 1 && motionDetail.mainVideoAuth.vipStatus === 2) || (typeIndex === 0 && motionDetail.threeDVideoAuth.vipStatus === 2)" class="limit-icon" src="https://img.chaolu.com.cn/ACT/training-record/202410/limit.png" alt="" />
      </div>
      <p v-if="motionDetail.isDel" class="del-tip f32 fw6">该动作已被删除</p>
      <!-- 计划详情   非vip时introduceAuth -->
      <div class="detail-box" v-if="motionDetail.introduceAuth.isLimit || motionDetail.colorIntroduce">
        <!-- 限免标识 -->
        <p class="detail-t f32 fw6 row-start-center">步骤 <img v-show="motionDetail.introduceAuth.vipStatus === 2" class="limit-icon" src="https://img.chaolu.com.cn/ACT/training-record/202410/limit.png" alt="" /></p>
        <img @click="downloadToast('')" v-if="motionDetail.introduceAuth.isLimit" class="detail-c f26" style="width: 100%" :src="motionDetail.introduceAuth.overlayImg" />
        <p v-else class="detail-c f26" v-html="motionDetail.colorIntroduce"></p>
      </div>
      <!-- 视频讲解 -->
      <div v-if="motionDetail.explainVideoAuth && motionDetail.explainVideoAuth.length" class="course-box">
        <h3 class="f32 fw6 row-start-center">视频讲解 <img v-show="motionDetail.explainVideoAuth[0].vipStatus === 2" class="limit-icon" src="https://img.chaolu.com.cn/ACT/training-record/202410/limit.png" alt="" /></h3>
        <!-- 没法看 遮罩 -->
        <div @click="downloadToast('')" v-if="motionDetail.explainVideoAuth[0].isLimit" class="video-list flex">
          <div class="video-item flex-none" v-for="(item, index) in motionDetail.explainVideoAuth" :key="index" :style="`background-image: url(${item.overlayImg})`">
            <van-icon name="play-circle" size="20" class="play-icon" />
          </div>
        </div>
        <div v-else class="video-list flex">
          <div @click="showVideoDialog(index)" class="video-item flex-none" v-for="(item, index) in motionDetail.explainVideoList" :key="item.url" :style="`background-image: url(${item.coverUrl})`">
            <van-icon name="play-circle" size="20" class="play-icon" />
          </div>
        </div>
      </div>

      <!-- 计划课程 -->
      <div class="course-box">
        <h3 class="f32 fw6">训练部位</h3>
        <muscle :muscleModel="motionDetail.muscleModel"></muscle>
      </div>
    </div>

    <go-introduction-button />
    <!-- 讲解视频 -->
    <van-popup v-model="isVideoShow" :overlay="false" style="width: 100%; height: 100%; background: #000">
      <div class="popup-con col-center-center">
        <van-icon @click="close" class="icon" color="#fff" size="28" name="cross" />
        <div v-if="motionDetail.explainVideoList && motionDetail.explainVideoList.length" class="mid">
          <!-- <video
            ref="popupVideo"
            class="video-js vjs-big-play-centered"
            playsinline="true"
            webkit-playsinline="true"
            autoplay
            controls
            x-webkit-airplay="true"
            x5-video-player-type="h5-page"
            x5-video-player-fullscreen="true"
            x5-video-orientation="portraint"
            style="object-fit: cover; width: 100%; height: 100%"
            :src="motionDetail.explainVideoList[explainIndex].url" /> -->
          <div id="k_dot" style="height: 80vh"></div>
        </div>
        <div class="v-list flex">
          <div class="v-item" @click="showVideoDialog(index)" v-for="(item, index) in motionDetail.explainVideoList" :key="item.url" :style="`background-image: url(${item.coverUrl})`">
            <div v-show="explainIndex === index" class="mask row-center-center">
              <img src="https://img.chaolu.com.cn/ACT/training-record/play-gif.gif" alt="" class="play-gif" />
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar/nav-bar'
import muscle from './components/muscle'
import goIntroductionButton from './components/go-introduction-button'
import { newAppBack, initBack, loginOut } from '@/lib/appMethod'
import { getParam, getRedString } from '@/lib/utils'
import wx from 'weixin-js-sdk'
import Aliplayer from 'aliyun-aliplayer'
import 'aliyun-aliplayer/build/skins/default/aliplayer-min.css'

export default {
  mixins: [userMixin],
  components: {
    navBar,
    muscle,
    goIntroductionButton
  },
  data() {
    return {
      checkTypes: [
        { name: '3D', url: '' },
        { name: '真人', url: '' },
      ],
      typeIndex: 0, // 真人mainVideoList 或 3d threeDImage
      realmanIndex: 0,

      motionDetail: {
        threeDVideoAuth: {},
        mainVideoAuth: {},
        introduceAuth: {},
        muscleModel: {},
      }, // 总详情
      isPlaying: false,
      isAndroid: false,
      isVideoShow: false,
      playbackRate: '1.0',

      explainIndex: 0, // 讲解视频index

      bannerPlayer: null,
      popupPlayer: null,
      bannerPlayauth: '',
      popupPlayauth: '',
    }
  },
  computed: {
    // 仅返回视频加密所需videoId等信息 再监听这些变化去请求playAuth以播放视频
    backgroundVideo() {
      // 每次切换视频源 速率都要重置一次
      this.$nextTick(() => {
        if (this.bannerPlayer) this.bannerPlayer.setSpeed(this.playbackRate * 1)
      })
      const m = this.motionDetail
      // 3d视频
      if (m.backgroundMode === 1 && this.typeIndex === 0 && m.threeDVideoPlay) {
        return m.threeDVideoPlay
      }
      // 真人视频
      if (m.backgroundMode === 1 && this.typeIndex === 1 && m.mainVideoList) {
        return m.mainVideoList[this.realmanIndex].videoPlay
      }

      if (m.backgroundMode === 2 && m.backgroundTemplate && m.backgroundTemplate.type === 'VIDEO') {
        return m.backgroundTemplate.videoPlay
      }
      return ''
    },
    // 仅返回视频加密所需videoId等信息 再监听这些变化去请求playAuth以播放视频
    backgroundVideoAngle() {
      const m = this.motionDetail

      // 真人视频
      if (m.backgroundMode === 1 && this.typeIndex === 1 && m.mainVideoList) {
        return { visualAngle: m.mainVideoList[this.realmanIndex].visualAngle, visualAngleName: m.mainVideoList[this.realmanIndex].visualAngleName }
      }

      return { visualAngle: '', visualAngleName: '' }
    },
    backgroundImage() {
      // isLimit有遮罩 vipStatus:2 限时免费
      const m = this.motionDetail
      // vip限制
      if (m.mainVideoAuth.isLimit) {
        return m.mainVideoAuth.overlayImg
      }
      if (m.mainVideoAuth.isLimit) {
        return m.threeDVideoAuth.overlayImg
      }

      // 啥也没有的情况下 默认返回
      if (m.backgroundMode === 2 && !m.backgroundTemplate) {
        return 'https://img.chaolu.com.cn/ACT/training-record/default-banner.png'
      }
      if (m.backgroundMode === 2 && m.backgroundTemplate.type === 'IMG') {
        return m.backgroundTemplate.url
      }
      return ''
    },
  },
  watch: {
    async backgroundVideo(v) {
      console.log(v, 'ssdfds')

      if (!v) return
      await this.getAuth(v.videoId)

      if (this.bannerPlayer) {
        this.bannerPlayer.replayByVidAndPlayAuth(v.videoId, this.bannerPlayauth)
        return
      }
// tPnLQ5Mc815RJsFPb30988270d80c48dfa1c68549e9d3ffbe
// web.chaolu.com.cn
      if (!this.isAndroid) {
        this.bannerPlayer = new Aliplayer(
          {
            id: 'j_cole',
            width: '100%',
            height: '100vw',
            rePlay: true,
            skinLayout: false, // 取消所以皮肤控件
            vid: v.videoId, // 必选参数。音视频ID。示例：1e067a2831b641db90d570b6480f****。
            playauth: this.bannerPlayauth, // 必选参数。音视频播放凭证。
            autoplay: true,
            license: {
              domain: 'web.chaolu.com.cn', // 申请 License 时填写的域名
              key: 'tPnLQ5Mc815RJsFPb30988270d80c48dfa1c68549e9d3ffbe' // 申请成功后，在控制台可以看到 License Key
            },
            playConfig: {
              // 可选参数
              EncryptType: v.encryptType, // 可选参数，设置加密类型为HLSEncryption时，则只会返回经过HLS标准加密的视频流。
              MtsHlsUriToken: v.mtsHlsUriToken
            },
            defaultDefinition: v.defaultDefinition
          },
          function (player) {
            console.log('The player is created.')
            // 自动播放 config没用 目的是调用wx.ready
            wx.config({
              debug: false,
              appId: 'abc',
              timestamp: 'abc',
              nonceStr: 'abc',
              signature: 'abc',
              jsApiList: 'abc',
            })
            wx.ready(() => {
              console.log('wx reday succe');
              player.play()
            })
            // 自动播放 config没用 目的是调用wx.ready
          }
        )
      }
    },
  },
  async created() {
    // 初始化
    await this.$getAllInfo()
    initBack()

    // ------
    if (!this.userId) {
      loginOut()
      return
    }

    // 获取链接参数
    this.params = getParam()
    this.params = {
      motionId: this.params.motionId || 11,
      userId: this.userId,
    }

    this.countPoint('500', '500', '5016', this.userId, this.params.motionId)

    const u = navigator.userAgent
    this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
    this.isPlaying = !this.isAndroid

    this.getMotion()
  },
  methods: {
    newAppBack,
    async playVideo() {
      this.isPlaying = true
      await this.getAuth(this.backgroundVideo.videoId)
      this.bannerPlayer = new Aliplayer(
        {
          id: 'j_cole',
          width: '100%',
          height: '100vw',
          rePlay: true,
          skinLayout: false, // 取消所以皮肤控件
          vid: this.backgroundVideo.videoId, // 必选参数。音视频ID。示例：1e067a2831b641db90d570b6480f****。
          playauth: this.bannerPlayauth, // 必选参数。音视频播放凭证。
          autoPlay: true,
          license: {
              domain: 'web.chaolu.com.cn', // 申请 License 时填写的域名
              key: 'tPnLQ5Mc815RJsFPb30988270d80c48dfa1c68549e9d3ffbe' // 申请成功后，在控制台可以看到 License Key
          },
          playConfig: {
            // 可选参数
            EncryptType: this.backgroundVideo.encryptType, // 可选参数，设置加密类型为HLSEncryption时，则只会返回经过HLS标准加密的视频流。
            MtsHlsUriToken: this.backgroundVideo.mtsHlsUriToken
          },
          defaultDefinition: this.backgroundVideo.defaultDefinition
        },
        function (player) {
          console.log('The player is created.')
        }
      )
    },
    getMotion() {
      this.$axios.post(this.baseURLApp + '/c/user/motion/shareDetail', this.params).then((res) => {
        this.motionDetail = res.data
        // 真人没有的情况下 默认展示3d 3d必填
        if (this.motionDetail.backgroundMode === 1 && !this.motionDetail.threeDVideoPlay) {
          this.typeIndex = 1
        }
        // 处理高亮文本
        this.motionDetail.colorIntroduce = this.motionDetail.introduce.highlightList.reduce((pre, label) => {
          return getRedString(pre, label, 'yellow')
        }, this.motionDetail.introduce.content)

        document.title = res.data.name
      })
    },

    // 切换 真人、3d
    changePlayType(i) {
      this.typeIndex = i
    },
    // 切换背景播放列表
    changePlayIndex() {
      if (this.realmanIndex === this.motionDetail.mainVideoList.length - 1) {
        this.realmanIndex = 0
      } else {
        this.realmanIndex++
      }
    },
    // 切换播放速率
    changePlayRate() {
      this.bannerPlayer.setSpeed(this.playbackRate === '1.0' ? 0.5 : 1)
      this.playbackRate = this.playbackRate === '1.0' ? '0.5' : '1.0'
    },
    showVideoDialog(i) {
      this.explainIndex = i
      this.isVideoShow = true
      this.$nextTick(async () => {
        const id = this.motionDetail.explainVideoList[i].videoId
        await this.getAuth(id, 'POPUP')
        console.log(this.popupPlayer, 'shhskdjfsdk')

        if (this.popupPlayer) {
          this.popupPlayer.replayByVidAndPlayAuth(id, this.popupPlayauth)
          return
        }

        this.popupPlayer = new Aliplayer(
          {
            id: 'k_dot',
            width: '100%',
            rePlay: true,
            skinLayout: false, // 取消所以皮肤控件
            vid: this.motionDetail.explainVideoList[i].videoId, // 必选参数。音视频ID。示例：1e067a2831b641db90d570b6480f****。
            playauth: this.popupPlayauth, // 必选参数。音视频播放凭证。
            autoPlay: true,
            license: {
              domain: 'web.chaolu.com.cn', // 申请 License 时填写的域名
              key: 'tPnLQ5Mc815RJsFPb30988270d80c48dfa1c68549e9d3ffbe' // 申请成功后，在控制台可以看到 License Key
            },

            playConfig: {
              // 可选参数
              EncryptType: this.motionDetail.explainVideoList[i].encryptType, // 可选参数，设置加密类型为HLSEncryption时，则只会返回经过HLS标准加密的视频流。
              MtsHlsUriToken: this.motionDetail.explainVideoList[i].mtsHlsUriToken
            },
            defaultDefinition: this.motionDetail.explainVideoList[i].defaultDefinition
          },
          function (player) {
            console.log('The player is created.')
          }
        )
      })
    },
    close() {
      this.popupPlayer.pause()
      this.isVideoShow = false
    },
    downloadToast(type) {
      if (!type) {
        this.$toast('可下载APP体验所有功能权益')
        return
      }

      if ((type === 'MAIN_VIDEO' && this.typeIndex === 1 && this.motionDetail.mainVideoAuth.isLimit) || (this.typeIndex === 0 && this.motionDetail.threeDVideoAuth.isLimit)) {
        this.$toast('可下载APP体验所有功能权益')
      }
    },
    getAuth(id, type = 'BANNER') {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios
        .post(`${this.baseURLApp}/th/ali/video/getPlayAuth`, {
          videoId: id,
        })
        .then((res) => {
          if (type === 'POPUP') this.popupPlayauth = res.data.playAuth
          this.bannerPlayauth = res.data.playAuth
          this.$toast.clear()
        })
        .catch(() => {
          this.$toast.clear()
        })
    },
  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  min-height: 100vh;
  background: #242424;
  color: #fff;
  * {
    box-sizing: border-box;
  }
  width: 100%;
  padding: calc(env(safe-area-inset-top)) 0 calc(140px + env(safe-area-inset-bottom));
  .banner-box {
    width: 100%;
    height: 100vw;
    position: relative;
    .limit-icon {
      position: absolute;
      left: 28px;
      top: 28px;
      height: 32px;
      z-index: 15;
    }
    .play-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      z-index: 2;
    }
    .banner {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .mask {
      background: linear-gradient(180deg, rgba(36, 36, 36, 0) 0%, #242424 100%);
      position: absolute;
      width: 100%;
      height: 50vw;
      bottom: -1px;
      z-index: 15;
      padding: 0 40px;
      .mask-con {
        position: relative;
        bottom: -30px;
        width: 100%;
        .check-btn {
          min-width: 90px;
          height: 56px;
          padding: 0 8px;
          background: rgba(0, 0, 0, 0.6);
          border-radius: 10px;
          &.ml34 {
            margin: 0 0 0 34px;
          }
          .check-item {
            width: 64px;
            height: 40px;
            border-radius: 6px 6px 6px 6px;
            opacity: 0.5;
            &.active {
              opacity: 1;
              background: rgba(255, 255, 255, 0.35);
            }
          }
          .icon {
            width: 24px;
            margin: 0 0 0 4px;
          }
        }
        h2 {
          margin: 36px 0 0;
        }
      }
    }
  }
  .del-tip {
    margin: 132px 0 0;
    text-align: center;
    color: #f03c18;
  }
  .detail-box {
    padding: 0 40px;
    margin: 94px 0 0;
    .detail-c {
      margin: 40px 0 0;
      line-height: 1.6;
      white-space: pre-line;
      .yellow {
        color: #ffde00;
      }
    }
  }

  .course-box {
    margin: 90px 0 0;
    padding: 0 0 0 40px;
    .limit-icon {
      width: 60px;
      margin: 0 0 0 12px;
    }
    h3 {
      margin: 0 0 40px;
    }
    .video-list {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      padding: 0 16px 0 0;
      .video-item {
        width: 308px;
        height: 386px;
        border-radius: 8px;
        position: relative;
        margin: 0 24px 0 0;
        background-size: cover;
        background-position: center;
        .play-icon {
          position: absolute;
          right: 16px;
          top: 16px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
  .opacity9 {
    opacity: 0.9;
  }
}
/deep/.prism-player video {
  object-fit: contain !important;
}
/deep/.van-popup {
  .popup-con {
    height: 100vh;
    box-sizing: border-box;
    .mid {
      width: 100%;
      max-height: 80vh;
      position: relative;
    }
    .icon {
      position: absolute;
      top: 40px;
      left: 40px;
    }
    .v-list {
      width: calc(100% - 32px);
      position: absolute;
      bottom: calc(32px + env(safe-area-inset-bottom));
      left: 32px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      .v-item {
        width: 96px;
        height: 96px;
        border-radius: 4px;
        margin: 0 20px 0 0;
        position: relative;
        background-size: cover;
        background-position: center;
        .mask {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
          position: absolute;
          left: 0;
          .play-gif {
            width: 48px;
          }
        }
      }
    }
  }
}
/deep/.yellow {
  color: #ffde00;
}
</style>
